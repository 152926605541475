import React, { useState } from "react";
import HeaderButton from "./design/HeaderButton";
import { useUser } from "../context/UserContext";
import AddTutelageModal from "./AddTutelageModal";
import { useTutelage } from "../context/TutelageContext";

const AddTutelage = () => {
  const [showModal, setShowModal] = useState(false);
  const { userPersonalData, session } = useUser();
  const { addTutelage } = useTutelage();

  const handleAddTutelage = (data) => {
    addTutelage(data, session?.token);
  };

  return (
    <>
      <HeaderButton label="Aggiungi Assistito" className="outline" onClick={() => setShowModal(true)} />
      {showModal && (
        <AddTutelageModal
          onAddTutelage={handleAddTutelage}
          user={userPersonalData}
          onHide={() => setShowModal(false)}
          setShowModal={setShowModal}
        ></AddTutelageModal>
      )}
    </>
  );
};

export default AddTutelage;
