import React, { createContext, useContext } from "react";
import axios from "axios";
import { API_URL } from "../constants/constants";
import { useUser } from "./UserContext";

const ApiContext = createContext();

const ApiProvider = ({ children }) => {

  const { session } = useUser();

  return <ApiContext.Provider value={{ createApi: (token) => axios.create({ baseURL: API_URL, headers: { Authorization: `Bearer ${token ?? session?.token ?? ""}`}})}}>{children}</ApiContext.Provider>;
};

function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within a ApiProvider");
  }
  return context;
}

export { ApiProvider, useApi };
