import "./styles/styles.css";
import "typeface-titillium-web";

import React from "react";
import { UserProvider } from "./context/UserContext";
import { I18nProvider } from "./context/I18nContext";
import { PCRCProvider } from "./context/PCRCContext";
import { HelperProvider } from "./context/HelperContext";
import { TutelageProvider } from "./context/TutelageContext";
import { MMGProvider } from "./context/MMGContext";

import { ApiProvider } from "./context/ApiContext";
import { BrowserRouter } from "react-router-dom";
import Router from "./router/Router";

import Layout from "./components/layout/Layout";

const App = () => {
  return (
    <PCRCProvider>
      <UserProvider>
        <BrowserRouter>
          <ApiProvider>
            <I18nProvider>
              <HelperProvider>
                <TutelageProvider>
                  <MMGProvider>
                    <Layout>
                      <Router />
                    </Layout>
                  </MMGProvider>
                </TutelageProvider>
              </HelperProvider>
            </I18nProvider>
          </ApiProvider>
        </BrowserRouter>
      </UserProvider>
    </PCRCProvider>
  );
};

export default App;
