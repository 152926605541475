import React, { useState } from "react";
import Card from "./design/Card";
import { prussianBlue } from "../styles/colors";
import Txt from "./Txt";
import { useUser } from "../context/UserContext";
import { useMMG } from "../context/MMGContext";
import styled from 'styled-components';

const TutoringCard = styled(Card)`
  margin-top: 0;
  padding: 0.4rem;
  @media only screen and (max-width: 370px) {
    margin-top: 1rem;
  }
`

const TutoringAlert = () => {
  const { user } = useUser();
  const { currentPatient } = useMMG();
  const [showAlert, setShowAlert] = useState(true);
  return (
    currentPatient?.fiscalCode !== user?.fiscalCode &&
    showAlert && (
      <TutoringCard>
        <div
          style={{
            margin: 0,
            border: "unset",
            borderLeft: `8px solid ${prussianBlue}`,
            backgroundPosition: "1rem",
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
          className="alert alert-info alert-dismissible fade show"
          role="alert"
        >
          <Txt>Stai attualmente gestendo il profilo di</Txt>{" "}
          <span style={{ textTransform: "capitalize" }}>{currentPatient?.fullName}</span>.{" "}
          <Txt>Per tornare a gestire il tuo profilo o selezionarlo un altro clicca sul pulsante "Altri assistiti"</Txt>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
            style={{
              right: 0,
              top: 0,
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </TutoringCard>
    )
  );
};

export default TutoringAlert;
