import React, { useState, useEffect } from "react";
import PageHeader from "../components/layout/PageHeader";
import HeaderTitle from "../components/design/HeaderTitle";
import HeaderDescription from "../components/design/HeaderDescription";
import HeaderButton from "../components/design/HeaderButton";
import Txt from "../components/Txt";
import HelpDocButton from "../components/HelpDocButton";
import { useUser } from "../context/UserContext";
import { useMMG } from "../context/MMGContext";
import { useTutelage } from "../context/TutelageContext";
import Breadcrumb from "../components/Breadcrumb";
import TutelagesTable from "../components/TutelagesTable";
import { useRouter } from "../hooks/useRouter";
import AddTutelage from "../components/AddTutelage";
import Header2 from "../components/design/Header2";

const TutelagesManager = () => {
  const { user, session } = useUser();
  const { setCurrentPatient, currentPatient } = useMMG();
  const { findTutelages, tutelages } = useTutelage();

  const [selectedTutelage, setSelectedTutelage] = useState(null);

  const router = useRouter();

  useEffect(() => {
    if (user && session?.token) {
      findTutelages(session.token);
    }
  }, [user, session?.token]);

  const handleProceed = () => {
    setCurrentPatient(selectedTutelage);
    router.push("/");
  };

  return (
    <div style={{ position: "relative" }}>
      <PageHeader style={{ position: "relative" }}>
        <div className="flex align-stretch">
          <div className="flex-column justify-start">
            <Breadcrumb currentLabel="Gestione altri assistiti" style={{ position: "absolute", top: 0 }} />
            <HeaderTitle>
              <Txt>Gestione altri assistiti</Txt>
            </HeaderTitle>
            <HeaderDescription style={{ maxWidth: "60%" }}>
              <Txt>
                In questa sezione puoi selezionare te stesso o un altro assistito per procedere alla modifica del Medico
                di Medicina Generale/Pediatria di Libera Scelta. Puoi effettuare l'operazione su te stesso o su un tuo
                figlio, se minorenne. Scegli dalla lista in basso su chi procedere oppure aggiungi una persona cliccando
                su "Aggiungi Assistito".
              </Txt>
            </HeaderDescription>
            <div style={{ marginTop: "1rem", marginLeft: "auto" }}>
              <AddTutelage />
              <HeaderButton label="Procedi" className="mr2rem" disabled={!selectedTutelage} onClick={handleProceed} />
            </div>
          </div>
        </div>
        <HelpDocButton images={["gestione-altri-assistiti/01.png", "gestione-altri-assistiti/02.png"]}></HelpDocButton>
      </PageHeader>
      <div style={{ padding: "2rem", margin: "1rem 0" }}>
        <Header2>
          <Txt>Assistiti inseriti</Txt>
        </Header2>
        <HeaderDescription>
          <Txt>Seleziona la riga relativa all'assistito su cui vuoi operare</Txt>.
          <br />
          <Txt>Se non è presente nella lista, clicca sul pulsante "Aggiunti Assistito" per inserirlo nell'elenco</Txt>.
        </HeaderDescription>
        <TutelagesTable
          currentTutelage={currentPatient}
          tutelages={tutelages}
          onTutelageClick={(tutelage) => setSelectedTutelage(tutelage)}
        ></TutelagesTable>
      </div>
    </div>
  );
};

export default TutelagesManager;
