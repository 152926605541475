import React, { useEffect, useState } from "react";
import { niceBlue } from "../../styles/colors";

const Paginator = ({ itemsForPage, count, onChangePage, ariaName }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTotalPages(Math.ceil(count / itemsForPage));
  }, [count, itemsForPage]);

  useEffect(() => {
    onChangePage?.(currentPage);
  }, [currentPage]);

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="flex-center w-100p" style={{ color: niceBlue, fontWeight: "bold" }}>
      <button class="shadow-button" onClick={previousPage} aria-label={`Vai a Pagina Precedente ${ariaName ?? ''}. Pagina corrente: ${currentPage} di ${totalPages}`}>
      <svg className="icon" style={{ fill: niceBlue, cursor: "pointer" }}>
        <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-chevron-left`}></use>
      </svg>
      </button>
      <div style={{ padding: "2rem", fontSize: "1.1rem" }}>{currentPage}</div>
      <div>/</div>
      <div style={{ padding: "2rem", fontSize: "1.1rem" }}>{totalPages}</div>
      <button className="shadow-button" onClick={nextPage} aria-label={`Vai a Pagina Successiva ${ariaName ?? ''}. Pagina corrente: ${currentPage} di ${totalPages}`}>
      <svg className="icon" style={{ fill: niceBlue, cursor: "pointer" }}>
        <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-chevron-right`}></use>
      </svg>
      </button>
    </div>
  );
};

export default Paginator;
