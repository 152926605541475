import React from "react";
import styled from "styled-components";
import { blueGray, yetAnotherDarkBlue, textBlue } from "../../styles/colors";

const InputField = ({ placeholder = "", label = "", ariaLabel, className = "", inputStyle = {}, style = {}, error, inputPrefix,...props }) => {
  return (
    <div className={className} style={style}>
      <div style={{ fontWeight: "bold", color: yetAnotherDarkBlue, fontSize: "0.9rem" }}>{label}</div>
      {
        inputPrefix 
        ?
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {inputPrefix}
            <StyledInput
              className="flex-grow-1"
              placeholder={placeholder}
              style={{ testTransform: "uppercase", maxWidth: "100%", ...inputStyle }}
              aria-label={ariaLabel ?? label.trim()}
              {...props} 
            />
          </div>
        :
          <StyledInput
            placeholder={placeholder}
            style={{ width: "100%", testTransform: "uppercase", ...inputStyle }}
            aria-label={ariaLabel ?? label.trim()}
            {...props} 
          />
      }
      {error&&<span style={{color:"red",fontWeight:"bold"}}>{error}</span>}
    </div>
  );
};

export default InputField;

const StyledInput = styled.input`
  border: 1px solid ${blueGray};
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: ${textBlue};
`;
