import React from "react";
import HeaderButton from "./design/HeaderButton";
import Modal from "./layout/Modal";
import HeaderTitle from "./design/HeaderTitle";
import Txt from "./Txt";
import HeaderDescription from "./design/HeaderDescription";
import RoundIconLabel from "./design/RoundIconLabel";

const RemoveTutelageModal = ({ onHide, onConfirm }) => {
  return (
    <Modal
      className="flex-center flex-column"
      style={{ width: "60vw", padding: "2rem", margin: "2rem" }}
      onHide={() => onHide?.()}
      s
    >
      <RoundIconLabel image="it_circle_minus.svg" color="red" size="4rem" className="mb-1r"></RoundIconLabel>
      <HeaderTitle className="mb-1r" style={{ textTransform: "none", fontSize: "2rem" }}>
        <Txt>Sei sicuro di voler rimuovere l'assistito</Txt>?
      </HeaderTitle>
      <HeaderDescription className="text-center" style={{ textTransform: "none" }}>
        <Txt>Stai per rimuovere l'assistito tra quelli da te gestiti</Txt>.
      </HeaderDescription>
      <div style={{ marginTop: "2rem" }}>
        <HeaderButton label="Annulla" className="mr2rem outline" onClick={() => onHide?.()} />

        <HeaderButton label="Rimuovi" className="mr2rem" onClick={() => onConfirm?.()} />
      </div>
    </Modal>
  );
};

export default RemoveTutelageModal;
