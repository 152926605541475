import React from "react";
import { IS_DEV } from "../constants/constants";
import { Switch, Route } from "react-router-dom";

//import Tutelages from "../pages/Tutelages";
import Home from "../pages/Home";
import Loader from "../components/design/Loader";
import { useUser } from "../context/UserContext";
import { usePCRC } from "../context/PCRCContext";

import TutelagesManager from "../pages/TutelagesManager";
import Error from "../pages/Error";
import DoctorManager from "../pages/DoctorManager";
import Success from "../pages/Success";
import FAQ from "../pages/FAQ";

const Router = () => {
  const { connected } = usePCRC();
  const { isLogged, session } = useUser();

  return (
    <Switch>
      {(connected || IS_DEV) && isLogged && session ? (
        <>
          <Route exact path="/tutelages">
            <TutelagesManager />
          </Route>
          <Route exact path="/error">
            <Error />
          </Route>
          <Route path="/error/:id">
            <Error />
          </Route>
          <Route exact path="/success">
            <Success />
          </Route>
          <Route exact path="/doctor">
            <DoctorManager />
          </Route>
          <Route exact path="/faq">
            <FAQ />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </>
      ) : (
        <Route path="/">
          <Loader />
        </Route>
      )}
    </Switch>
  );
};

export default Router;
