const prussianBlue = "#00446d";
const niceBlue = "rgba(21, 105, 155, 1)";
const lightBlue = "rgba(217, 241, 255, 0.49)";
const turquoise = "#00abac";
const lightGray = "#eeeeee";
const darkBlue = "rgb(50, 62,72)";
const backgroundGray = "#f9fafb";
const azure = "#0caeaf";
const ceruleanBlue = "#0073e6";
const breadcrumbBlue = "#5C6F82";
const azureBlue = "#E6E9F2";
const blueGray = "rgb(180, 194, 221)";
const orange = "rgb(217,126,0)";
const intenseBlue = "#17324D";
const yetAnotherDarkBlue = "rgb(0, 51, 84)";
const textBlue = "#435a70";
const disabledGray = "rgb(171,185,193)";

export {
  azure,
  breadcrumbBlue,
  prussianBlue,
  niceBlue,
  lightBlue,
  turquoise,
  intenseBlue,
  lightGray,
  darkBlue,
  ceruleanBlue,
  azureBlue,
  blueGray,
  backgroundGray,
  orange,
  yetAnotherDarkBlue,
  textBlue,
  disabledGray,
};
