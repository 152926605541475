import React from "react";
import styled from "styled-components";

import { breadcrumbBlue, darkBlue } from "../styles/colors";
import { TextStyle2 } from "../styles/text-styles";
import { useI18n } from "../context/I18nContext";
import { useRouter } from "../hooks/useRouter";

const Breadcrumb = ({ currentLabel = "", style = {} }) => {
  const { translate } = useI18n();

  const router = useRouter();

  return (
    <div className="flex" style={{ marginTop: "10px", ...style }}>
      <StyledFragment tabIndex="0" href="/" onClick={() => router.push("/")}>{translate("breadcrumb_home")}</StyledFragment>
      <StyledSeparator>{translate("breadcrumb_separator")}</StyledSeparator>
      <StyledFragment className="active">{translate(currentLabel)}</StyledFragment>
    </div>
  );
};

export default Breadcrumb;

const StyledFragment = styled.a`
  ${TextStyle2}
  text-transform: uppercase;
  text-decoration: underline;
  color: ${darkBlue};
  font-weight: bold;
  cursor: pointer;
  &.active {
    text-decoration: none;
    font-weight: bold;
    color: ${breadcrumbBlue};
    &:hover {
      cursor: default;
      text-decoration: none;
    }
  }
`;

const StyledSeparator = styled.div`
  ${TextStyle2};
  margin: 0 5px;
`;
