import styled from "styled-components";
import { TextStyle, TextStyle2 } from "../styles/text-styles";

const PageTitle = styled.h1`
  ${TextStyle}
  margin-bottom: 0;
  margin-top: 50px;
  font-size: ${(props) => props.fontSize};
`;

const PageSubTitle = styled.p`
  ${TextStyle2};
  font-size: ${(props) => props.fontSize};
`;

export { PageTitle, PageSubTitle };
