import React, { useEffect, useState } from "react";
import InputField from "./design/InputField";
import HeaderButton from "./design/HeaderButton";
import StyledSelect from "./design/StyledSelect";

const allCitiesOption = {
  value: null,
  label: "Tutti",
};
const doctorTypes = [
  { value: null, label: "Tutti" },
  { value: "1", label: "Medico di Medicina Generale" },
  { value: "2", label: "Pediatra di Libera Scelta" },
];

const DoctorsFilterBar = ({ mmgs = [], onListChange }) => {
  const [fullNameFilter, setFullNameFilter] = useState("");
  const [cityFilter, setCityFilter] = useState(allCitiesOption);
  const [doctorTypeFilter, setDoctorTypeFilter] = useState(doctorTypes[0]);

  const [cities, setCities] = useState([]);

  useEffect(() => {
    const foundCities = (mmgs || []).reduce((acc, { descComuneStudio }) => {
      return Array.from(new Set([...acc, descComuneStudio]));
    }, []);
    setCities([allCitiesOption, ...foundCities.map((el) => ({ label: el, value: el }))]);
  }, [mmgs]);

  const handleSearchText = (searchText) => {
    if (searchText) {
      setFullNameFilter(searchText);
    } else {
      setFullNameFilter("");
    }
  };

  const filterMMGByFullName = (mmg, text) => {
    const fields = ["nome", "cognome"];
    const filters = text.split(" ").map((el) => el.replaceAll(" ", ""));
    // un customer viene incluso se i suoi campi 'fields' matchano tutti con almeno uno dei filtri selezionati
    return filters.every((filter) =>
      fields.some((field) => `${mmg[field] || ""}`.toLowerCase().includes(`${filter}`.toLowerCase()))
    );
  };

  const applyFilter = (type, city, text) => {
    let filtered = mmgs;

    if (type?.value) {
      filtered = filtered.filter((el) => el.codTipoMedico === type.value);
    }

    if (city?.value) {
      filtered = filtered.filter((el) => el.descComuneStudio === city.value);
    }

    if (text) {
      filtered = filtered.filter((mmg) => filterMMGByFullName(mmg, text));
    }

    onListChange?.(filtered);
  };

  const handleCityFilter = (option) => {
    setCityFilter(option);
  };

  const resetFilter = () => {
    setDoctorTypeFilter(doctorTypes[0]);
    setCityFilter(allCitiesOption);
    setFullNameFilter("");
    applyFilter(doctorTypes[0], allCitiesOption, "");
  };

  return (
    <div
      className="flex justify-start align-start col-xs"
      style={{ borderBottom: "2px solid #ececec", paddingBottom: "2rem" }}
    >
      <div className="flex flex-1 flex-wrap">
        <InputField
          label="Nome e Cognome"
          onChange={(e) => handleSearchText(e.target.value)}
          style={{ width: "38%" }}
          value={fullNameFilter}
        ></InputField>
        <StyledSelect
          label="Comune"
          className="ml-3"
          defaultValue={cityFilter}
          value={cityFilter}
          options={cities}
          onChange={handleCityFilter}
          placeholder="Seleziona"
          style={{ width: "38%" }}

        //getOptionLabel={(value) => value}
        />
        <StyledSelect
          label="Tipo di Medico"
          className="ml-3"
          defaultValue={doctorTypeFilter}
          value={doctorTypeFilter}
          options={doctorTypes}
          onChange={setDoctorTypeFilter}
          placeholder="Seleziona"
          style={{ width: "18%" }}

        //getOptionLabel={(value) => value}
        />
      </div>
      <div
        className="flex justify-start pl-0-xs"
        style={{ margin: "1rem", marginBottom: 0, paddingLeft: "4rem", alignSelf: "stretch" }}
      >
        <HeaderButton
          label="Applica"
          style={{ minWidth: "8rem", alignSelf: "flex-end", margin: 0 }}
          onClick={() => applyFilter(doctorTypeFilter, cityFilter, fullNameFilter)}
        ></HeaderButton>
        <HeaderButton
          className="link"
          label="Reset Filtri"
          style={{ marginBottom: "0.2rem", alignSelf: "flex-end", minWidth: "8rem" }}
          onClick={resetFilter}
        ></HeaderButton>
      </div>
    </div>
  );
};

export default DoctorsFilterBar;
