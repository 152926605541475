import React, { useState } from "react";
import { niceBlue } from "../styles/colors";
import Card from "./design/Card";
import styled from "styled-components";
import Txt from "./Txt";
import HelpCarousel from "./HelpCarousel";

const HelpDocButton = ({ style = {}, images }) => {
  const [showHelpCarousel, setShowHelpCarousel] = useState(false);
  return (
    <div style={{ position: "absolute", right: "0.5rem", bottom: "-2rem" }}>
      <button className="shadow-button" 
        onClick={() => setShowHelpCarousel(true)}
      >
      <Card
        className="flex-center"
        style={{ ...style, background: niceBlue, padding: "0.5rem", margin: 0, cursor: "pointer" }}
      >
        <Label className="mr-1r">
          <Txt>Guida Utente</Txt>
        </Label>
        <img src={`${process.env.PUBLIC_URL}/images/dochelp.png`} alt="Guida Utente"></img>
      </Card>
      </button>
      {showHelpCarousel && <HelpCarousel images={images} onClose={() => setShowHelpCarousel(false)}></HelpCarousel>}
    </div>
  );
};

export default HelpDocButton;

const Label = styled.div`
  ${Card}:hover & {
    display: block;
  }
  color: white;
  display: none;
`;
