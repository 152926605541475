import React from "react";
import Header3 from "./design/Header3";
import Txt from "./Txt";
import HeaderDescription from "./design/HeaderDescription";

const AlreadyChangedMsg = ({ className = "" }) => {
  return (
    <div className={className}>
      <Header3 style={{ color: "#ce8e19", textTransform: "uppercase", letterSpacing: "1px" }}>
        <Txt>Attenzione</Txt>
      </Header3>
      <HeaderDescription>
        <Txt>
          La funzionalità "Cambio Medico non è al momento disponibile in quanto di recente è stato effettuato un cambio.
          Potrai effettuare una nuova scelta da Portale soltanto dopo 30 giorni dalla precedente. Per procedere ad un
          nuovo cambio prima dei 30 giorni è necessario recarsi presso il Distretto Sanitario di appartenenza
        </Txt>
        .
      </HeaderDescription>
    </div>
  );
};

export default AlreadyChangedMsg;
