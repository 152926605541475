import React, { createContext, useContext, useEffect, useState } from "react";

const PCRCStateContext = createContext();

function PCRCProvider({ children }) {
  const [connected, setConnected] = useState(false);

  const connectedHandler = (event) => {
    setConnected(true);
  };

  useEffect(() => {
    document.addEventListener("connected", connectedHandler);
    return () => document.removeEventListener("connected", connectedHandler);
  }, []);

  function comunicateToParent({ type, payload }) {
    try {
      window.parentIFrame.sendMessage({ type, payload });
    } catch (err) {
      console.log(err);
    }
  }
  const navigateTo = (page) => comunicateToParent({ type: "NAVIGATE_TO", payload: page });
  const setHeaderImages = (payload) => comunicateToParent({ type: "SET_HEADER_IMAGES", payload });
  const showModal = (html) => comunicateToParent({ type: "SHOW_MODAL", payload: html });
  const showMessage = (payload) => comunicateToParent({ type: "SHOW_MESSAGE", payload });
  const resetBodyPadding = () => comunicateToParent({ type: "RESET_PAGE_BODY_PADDING" });
  const setMenu = (menu) => comunicateToParent({ type: "SET_MENU", payload: menu });
  const scrollTop = () => comunicateToParent({ type: "SCROLL_TOP" });

  const state = {
    navigateTo,
    setHeaderImages,
    showModal,
    showMessage,
    resetBodyPadding,
    setMenu,
    scrollTop,
    connected,
  };
  return <PCRCStateContext.Provider value={state}>{children}</PCRCStateContext.Provider>;
}

function usePCRC() {
  const context = useContext(PCRCStateContext);
  if (context === undefined) {
    throw new Error("usePCRCState must be used within a PCRCProvider");
  }
  return context;
}

export { PCRCProvider, usePCRC };
