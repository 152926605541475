import React, {
  createContext,
  useContext,
  useReducer,
} from "react";
import { useApi } from "./ApiContext";
import { useRouter } from "../hooks/useRouter";

const TutelageContext = createContext();

function TutelageReducer(state, action) {
  if (action.type === "SET_TUTELAGES") {
    return { ...state, tutelages: action.payload };
  }
  else {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function TutelageProvider({ children }) {
  const { createApi } = useApi();
  const router = useRouter();
  function setTutelages(data) {
    dispatch({ type: "SET_TUTELAGES", payload: data });
  }

  const [state, dispatch] = useReducer(TutelageReducer, {
    tutelages: [],
    removeTutelage,
    addTutelage,
    findTutelages
  });

  async function findTutelages(token) {
    try {
      const { data } = await createApi(token).get(`tutelages/find`);
      setTutelages(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function removeTutelage(targetFiscalCode, token) {
    try {
      await createApi(token).post(`tutelages/delete`, { targetFiscalCode });
    } catch (error) {
      console.log(error);
    } finally {
      findTutelages(token);
    }
  }



  async function addTutelage(
    { targetFiscalCode, selfCertification, targetIdTeam }, token
  ) {
    try {
        await createApi(token).post(`tutelages/create`, {
          targetFiscalCode,
          selfCertification,
          targetIdTeam,
          familyRelation: "figlio",
        });     
    } catch (error) {
      const { response } = error;
      let redirectPage=`/error/`
      if(response.status == 400){
        switch(response.data.code){
          case"idteam":
            redirectPage+="9"
            break
          case  "invalid_input":
            redirectPage+="4"
            break
          case "self-certification":
            redirectPage+="6"
            break
          case "not-found":
            redirectPage+="7"
            break
          case "duplicate":
            redirectPage+="8"
            break
          case "decesead":
            redirectPage+="10"
              break
          case "expired":
            redirectPage+="11"
              break
          case "adult":
            redirectPage+="12"
              break
          default:
            redirectPage+="" 
            break
        }
      }
      
      router.push(redirectPage);
    } finally {
      findTutelages(token);
    }
  }

  return (
    <TutelageContext.Provider value={state}>
      {children}
    </TutelageContext.Provider>
  );
}

function useTutelage() {
  const context = useContext(TutelageContext);
  if (context === undefined) {
    throw new Error("useTutelage must be used within a TutelageProvider");
  }
  return context;
}

export { TutelageProvider, useTutelage };
