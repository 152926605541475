import { Switch } from "@material-ui/core";
import { yetAnotherDarkBlue, darkBlue, ceruleanBlue } from "../../styles/colors";
import React from "react";
import styled from "styled-components";

const SwitchField = ({ label = "", containerClassName = "", switchClassName = "", ...props }) => {
  return (
    <div className={`flex-center ${containerClassName}`}>
      <div
        style={{
          fontWeight: "bold",
          color: yetAnotherDarkBlue,
          fontSize: "0.9rem",
          display: "flex",
          paddingRight: "4rem",
        }}
      >
        {label}
      </div>
      <StyledSwitch
        {...props}
        className={switchClassName}
        checkedIcon={
          <svg
            className="icon"
            style={{ fill: "white", background: "black", width: "20px", height: "20px", borderRadius: "50%" }}
          >
            <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-check`}></use>
          </svg>
        }
        icon={
          <svg
            className="icon"
            style={{ fill: "white", background: "black", width: "20px", height: "20px", borderRadius: "50%" }}
          >
            <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-close`}></use>
          </svg>
        }
      ></StyledSwitch>
    </div>
  );
};

export default SwitchField;

const StyledSwitch = styled(Switch)`
  &.MuiSwitch-root {
    //width: 70px;
  }
  .MuiSwitch-track {
    background-color: ${ceruleanBlue} !important;
    opacity: 0.1 !important;
  }

  .MuiSwitch-thumb {
    color: ${darkBlue} !important;
    // border: 3px solid white;
  }
  .MuiSwitch-switchBase.Mui-checked {
    //transform: translateX(38px);
  }
`;
