import React, { createContext, useContext, useEffect, useReducer } from "react";
import { TEST_FISCAL_CODE } from "../constants/constants";

const UserStateContext = createContext();

function UserReducer(state, action) {
  switch (action.type) {
    case "SET_USER": {
      return { ...state, user: action.payload };
    }
    case "SET_PERSONAL_DATA": {
      return { ...state, userPersonalData: action.payload };
    }
    case "SET_SESSION": {
      return { ...state, session: action.payload };
    }

    case "SET_IS_LOGGED": {
      return { ...state, isLogged: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const initialState = {
    session: null,
    user: null,
    isLogged: false,
    userPersonalData: null,
    setUserPersonalData,
  };

  const [state, dispatch] = useReducer(UserReducer, initialState);

  const handleSessionChange = async (event) => {
    const { detail } = event;
    const { user, session } = detail;
    dispatch({ type: "SET_IS_LOGGED", payload: !!session });
    dispatch({ type: "SET_SESSION", payload: session });
    dispatch({ type: "SET_USER", payload: user });
  };

  function setUserPersonalData(userPersonalData) {
    dispatch({ type: "SET_PERSONAL_DATA", payload: userPersonalData });
  }

  useEffect(() => {
    document.addEventListener("session-change", handleSessionChange);
    return () => document.removeEventListener("session-change", handleSessionChange);
  }, []);

  useEffect(() => {
    if (TEST_FISCAL_CODE) {
      dispatch({ type: "SET_SESSION", payload: "test" });
      dispatch({
        type: "SET_USER",
        payload: { fiscalCode: TEST_FISCAL_CODE, name: "Francesco", familyName: "Valente" },
      });
      dispatch({ type: "SET_IS_LOGGED", payload: true });
    }
  }, []);

  return <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>;
}

function useUser() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
