import React, { useState, useRef } from "react";
import Card from "./design/Card";
import Header3 from "./design/Header3";
import Header2 from "./design/Header2";
import { niceBlue } from "../styles/colors";
import Txt from "./Txt";
import RoundIconLabel from "./design/RoundIconLabel";
import { useMMG } from "../context/MMGContext";
import { useUser } from "../context/UserContext";
import styled from "styled-components";

const IconContainer = styled.div`
  margin-left: auto;
  @media only screen and (max-width: 400px) {
    margin-left: 0px;
  }
`;

const HealthBookDownloadCard = () => {
  const { currentPatient, fetchHealthBook } = useMMG();
  const [base64Pdf, setBase64Pdf] = useState(null);
  const inputEl = useRef(null);
  const { session } = useUser();

  const downloadHealtBook = async () => {
    const base64 = await fetchHealthBook(currentPatient.fiscalCode, session?.token);
    setBase64Pdf(base64);
    inputEl.current.click();
  };
  return (
    <>
      <a aria-label="Scarica Libretto Sanitario" tabIndex="0" href={`data:application/pdf;base64,${base64Pdf}`} target="_blank" download="libretto-sanitario.pdf">
        <div ref={inputEl}></div>
      </a>
      <Header2>
        <Txt>Documenti dell'assistito</Txt>
      </Header2>
      <Card className="sm flex align-center flex-wrap" onClick={downloadHealtBook} style={{ cursor: "pointer" }}>
        <Header3 className="">
          <Txt>Libretto Sanitario</Txt>
        </Header3>

        <IconContainer>
        <RoundIconLabel
          label="download PDF"
          color={niceBlue}
          image="download.svg"
          className=""
        ></RoundIconLabel>
        </IconContainer>
      </Card>
    </>
  );
};

export default HealthBookDownloadCard;
