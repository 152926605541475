import React from "react";
import { orange } from "../styles/colors";
import styled from "styled-components";


const WarningBox = ({ text, title, className, children }) => {
  return (
    <WarningBoxContainer
      className={className}
    >
      <Title>
        <svg
          className="icon"
          style={{
            fill: orange,
            padding: "0.2rem",
            transform: "translateY(-4px)",
            width: "40px",
            height: "40px",
            margin: "0 0.5rem",
          }}
        >
          <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-horn`}></use>
        </svg>
        {title}
      </Title>
      {children}
    </WarningBoxContainer>
  );
};

export default WarningBox;

const Title = styled.div`
  position: absolute;
  left: 20px;
  top: -20px;
  background: white;
  color: ${orange};
  padding: 0 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const WarningBoxContainer = styled.div`
  border: 1px solid ${orange};
  border-radius: 5px;
  padding: 1.5rem;
  padding-left: 3rem;
  position: relative;
  @media only screen and (max-width: 200px) {
    padding-top: 3rem;
  }
`;

