import React, { useEffect } from "react";
import { darkBlue } from "../styles/colors";
import HeaderTitle from "../components/design/HeaderTitle";
import HeaderDescription from "../components/design/HeaderDescription";
import Button from "../components/design/Button";
import { useRouter } from "../hooks/useRouter";
import { useParams, useLocation } from "react-router-dom";
import Txt from "../components/Txt";
import { usePCRC } from "../context/PCRCContext";

const genericErrorMessage = "Si è verificato un errore, pertanto non è possibile procedere con la richiesta"

const Error = () => {
  const { scrollTop } = usePCRC();

  const { id } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const desc = query?.get("desc");

  const router = useRouter();

  const handleGoHome = () => {
    window.parentIFrame.sendMessage({ type: "NAVIGATE_TO", payload: "/" });
  };

  const handleGoDoctors = () => {
    router.push("/doctor");
  }

  useEffect(scrollTop, []);

  return (
    <div className="flex-center p-3r flex-column">
      <img
        style={{ border: `5px solid ${darkBlue}`, padding: "0.5rem", borderRadius: "50%" }}
        src={`${process.env.PUBLIC_URL}/images/close.svg`}
        alt=""
      ></img>

      <HeaderTitle className="my-2r">
        {!id && (
          <Txt>Servizio non disponibile</Txt>
        )}
        {id == 1 && (
          <Txt>Utente non autorizzato</Txt>
        )}
        {id == 2 && (
          <Txt>Servizio non disponibile</Txt>
        )}
        {id == 3 && (
          <Txt>Scelta non applicabile</Txt>
        )}
        {id == 4 || id==5|| id==6 && (
          <Txt>Dati inseriti non validi</Txt>
        )}
        {id == 7 && (
          <Txt>Codice fiscale non presente nell’anagrafe assistiti</Txt>     
        )}
        {id == 8 && (
          <Txt>Assistito già presente</Txt>     
        )}
        {id == 9 && (
          <Txt>Nessuna corrispondenza tra il codice fiscale ed il numero di tessera</Txt>     
        )}
        {id == 10 && (
          <Txt>Assistito deceduto</Txt>     
        )}
        {id == 11 && (
          <Txt>Iscrizione ASL Scaduta</Txt>     
        )}
        {id == 12 && (
          <Txt>Assistito maggiorenne</Txt>     
        )}
        {id == 13 && (
          <Txt>Impossibile cambiare medico</Txt>     
        )}


      </HeaderTitle>
      <HeaderDescription className="text-center">
        {!id && (
          <>
            <Txt>Si è verificato un errore, pertanto non è possibile procedere con la richiesta</Txt>.
            <br />
            <Txt>Ti invitiamo a riprovare più tardi</Txt>.
          </>
        )}
        {id == 1 && (
          <>
            <Txt>L'utente non è presente nell'anagrafe regionale assistiti</Txt>.
            <br />
            <Txt>Pertanto non è possibile utilizzare i servizi di Scelta e Revoca MMG/PLS</Txt>.
          </>
        )}
        {id == 2 && (
          <>
            <Txt>Il servizio Scelta e Revoca non è al momento disponibile</Txt>.
            <br />
            <Txt>Ti invitiamo a riprovare più tardi</Txt>.
          </>
        )}
        {
          id == 3 && (
            <>
              <Txt>{desc ?? genericErrorMessage}</Txt>.
            </>
        )}
        {
          id == 4 && (
            <>
            <br />
            <Txt>Attenzione, il codice fiscale e la tessera sanitaria dell'assistito tutelato non corrispondono.</Txt>
            </>
          )
        }
        {
          id == 5 && (
            <>
            <br />
            <Txt>Attenzione: il codice fiscale e/o il numero di Tessera sanitari inseriti non sono validi.</Txt>
            </>
          )
        }
        {
          id == 6 && (
            <>
            <br />
            <Txt>Attenzione: per procedere è necessario accettare l’autorizzazione al trattamento dei dati del minore</Txt>
            </>
          )
        }
        {
          id == 7 && (
            <>
            <br />
            <Txt>Attenzione: il codice fiscale inserito non risulta presente nell’anagrafe assistiti regionale. Verificare il valore inserito e, se corretto, rivolgersi alla propria ASL di assistenza</Txt>
            </>
          )
        }
        {
          id == 8 && (
            <>
            <br />
            <Txt>Attenzione: l’assistito che si sta tentando di aggiungere è già presente tra i suoi figli</Txt>
            </>
          )
        }
        {
          id == 9 && (
            <>
            <br />
            <Txt>Attenzione: in base ai dati presenti nell'anagrafe regionale, non c'è corrispondenza tra il codice fiscale ed il numero di tessera sanitaria inseriti</Txt>
            </>
          )
        }
        {
          id == 10 && (
            <>
            <br />
            <Txt>Attenzione: in base ai dati presenti nell'anagrafe regionale, l'assistito risulta deceduto. Pertanto, non è possibile aggiungerlo</Txt>
            </>
          )
        }
        {
          id == 11 && (
            <>
            <br />
            <Txt>Attenzione: in base ai dati presenti nell'anagrafe regionale, l'iscrizione dell'assistito all'ASL risulta scaduta</Txt>
            </>
          )
        }
        {
          id == 12 && (
            <>
            <br />
            <Txt> Attenzione: non è possibile inserire i propri figli se maggiorenni</Txt>
            </>
          )
        }
        {
          id == 13 && (
            <>
            <br />
            <Txt> Attenzione: Impossibile cambiare medico in quanto è stato cambiato da meno di 30 giorni</Txt>
            </>
          )
        }
      </HeaderDescription>
      {id >= 3 && id!==13 && (
        <Button
          style={{ backgroundColor: darkBlue, padding: "0.5rem 2rem" }}
          label="Torna alla lista medici"
          onClick={handleGoDoctors}
        ></Button>
      )}
      <Button
        style={{ backgroundColor: darkBlue, padding: "0.5rem 2rem" }}
        label="Torna alla homepage"
        onClick={handleGoHome}
      ></Button>
    </div>
  );
};

export default Error;
