import React, { useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeaderButton from "./design/HeaderButton";
import { usePCRC } from "../context/PCRCContext";
import guideLabels from "../constants/guideLabels";

const HelpCarousel = ({ onClose, images = [] }) => {
  const { scrollTop } = usePCRC();
  useEffect(() => {
    scrollTop();
    function noScroll(e) {
      window.scrollTo(0, 0);
    }
    document.addEventListener("scroll", noScroll);
    return () => document.removeEventListener("scroll", noScroll);
  }, []);

  return (
    <CarouselContainer>
      <HeaderButton
        label="Esci da Guida Utente"
        className="outline tutorial-button"
        afterIcon="it-close"
        onClick={onClose}
      ></HeaderButton>
      <Carousel
        className="h-30p minh-30r"
        //statusFormatter={(current, total) => `${current} ${translate("site_tour_out_of_pages")} ${total}`}
        showThumbs={false}
        dynamicHeight={true}
        showStatus={false}
        labels={{
          rightArrow: "Pagina Successiva",
          leftArrow: "Pagina Precedente",
          item: "Pagina numero ",
        }}
      >
        {images.map((imgUrl, index) => (
          <div key={index}>
            <img
              style={{ width: "auto", maxWidth: "100%" }}
              alt={guideLabels[imgUrl] ?? ""}
              src={`${process.env.PUBLIC_URL}/images/guida/${imgUrl}`}
            />
          </div>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default HelpCarousel;

const CarouselContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 10;
  width: 100%;
  background: rgba(13, 49, 86, 1);
  height: 100vh;

  & .tutorial-button {
    border-color: white !important;
    padding: 0;
    color: white;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    &:hover {
      color: white;
    }
    .icon {
      width: 32px;
      height: 32px;
    }
  }

  & .carousel-root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .carousel-slider {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .dot {
        box-shadow: none !important;
      }
      .control-arrow {
        position: absolute;
        height: 2rem;
        top: unset;
        bottom: 1.5%;
        opacity: 1;
        &:focus {
          box-shadow: none !important;
          border-color: none !important;
        }
        &.control-disabled {
          display: block;
          opacity: 0.4;
        }
        &.control-prev {
          left: 40%;
          &::before {
            content: "<";
          }
        }
        &.control-next {
          right: 40%;
          &::before {
            content: ">";
          }
        }
        &:hover {
          background: unset;
        }
        &::before {
          border: none;
          color: white;
        }
      }
    }
  }
`;
