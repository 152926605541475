import styled from "styled-components";
import { backgroundGray, darkBlue } from "../../styles/colors";

const PageHeader = styled.div`
  position: relative;
  background-color: ${backgroundGray};
  padding: 3rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  color: ${darkBlue};
  @media only screen and (max-width: 500px) {
    padding: 3rem 1rem;
  }
`;

export default PageHeader;
