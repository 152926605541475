import React, { useState } from "react";
import styled from "styled-components";

const WhiteBox = styled.div`
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const DropdownBox = ({ children, preHeader, header, style, className, isHeaderClickable }) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <WhiteBox className={"d-flex flex-column pt-3 pb-3 pl-3 " + className} style={{ ...style }}>
      <div className="d-flex pr-4">
        {preHeader}
        <div className="d-flex flex-grow-1 align-items-center" onClick={() => isHeaderClickable && setIsOpen(!isOpen)} style={isHeaderClickable && { cursor: "pointer" }}>
          {header}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <svg className="icon d-block ml-3" stroke="black" onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: "pointer" }}>
            <use href={`/static/bootstrap-italia/svg/sprite.svg#it-${isOpen ? "collapse" : "expand"}`}></use>
          </svg>
        </div>
      </div>
      {
        isOpen && children
      }
    </WhiteBox>
  )
}

export default DropdownBox;
