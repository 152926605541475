import styled from "styled-components";
const Counter = styled.div`
  background: green;
  color: white;
  padding: 0.5rem;
  border-radius: 10px;
  padding: 0 0.8rem;
  font-weight: normal;
  font-size: 0.8rem;
  margin: 0 1rem;
`;

export default Counter;
