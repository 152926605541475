import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useApi } from "./ApiContext";
import { useUser } from "./UserContext";
import { getFullName } from "../lib/utils";
import { useRouter } from "../hooks/useRouter";
import { SKIP_CHANGE_MMG_API } from "../constants/constants";

const MMGContext = createContext();

const codAnomaliaErrors = ["900056"]

function MMGReducer(state, action) {
  switch (action.type) {
    case "SET_MMGS": {
      return { ...state, mmgs: action.payload };
    }
    case "SET_SELECTED_MMG": {
      return { ...state, selectedMMG: action.payload };
    }
    case "SET_PERSONAL_DATA": {
      return { ...state, personalData: action.payload };
    }
    case "SET_CURRENT_PATIENT": {
      return { ...state, currentPatient: action.payload };
    }
    case "SET_CURRENT_MMG": {
      return { ...state, currentMMG: action.payload };
    }
    case "SET_SUCCESS_CODE": {
      return { ...state, successCode: action.payload };
    }
    case "SET_SERVICE_STATE": {
      return { ...state, serviceState: action.payload };
    }
    case "RESET_STATE": {
      return action.payload;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MMGProvider({ children }) {
  const initialState = {
    mmgs: null,
    selectedMMG: null,
    personalData: null,
    currentPatient: null,
    currentMMG: null,
    successCode: null,
    serviceState: null,

    changeMMG,
    revocaMMG,
    findMMGs,
    fetchPersonalData,
    setCurrentPatient,
    setCurrentMMG,
    fetchHealthBook,
    generateMMGReceipt,
    setSuccessCode,
    checkService,
    setServiceState,
  };

  const [state, dispatch] = useReducer(MMGReducer, initialState);

  const { personalData, currentPatient, serviceState } = state;

  const { createApi } = useApi();
  const { user, setUserPersonalData, isLogged, session } = useUser();
  const router = useRouter();

  const setMMGs = (data) => dispatch({ type: "SET_MMGS", payload: data });
  const setPersonalData = (data) => dispatch({ type: "SET_PERSONAL_DATA", payload: data });
  const resetState = () => dispatch({ type: "RESET_STATE", payload: { ...initialState, serviceState } });

  function setSuccessCode(code) {
    dispatch({ type: "SET_SUCCESS_CODE", payload: code });
  }

  function setCurrentMMG(mmg) {
    dispatch({ type: "SET_CURRENT_MMG", payload: mmg });
  }

  function setServiceState(serviceState) {
    dispatch({ type: "SET_SERVICE_STATE", payload: serviceState });
  }

  function setCurrentPatient(data) {
    resetState();
    dispatch({ type: "SET_CURRENT_PATIENT", payload: data });
  }

  useEffect(() => {
    if (isLogged && session?.token) {
      checkService();
    }
  }, [isLogged, session?.token]);

  useEffect(() => {
    if (serviceState && user && !currentPatient) {
      setCurrentPatient({ fiscalCode: user.fiscalCode, fullName: getFullName(user.name, user.familyName) });
    }
  }, [user, currentPatient, serviceState]);

  useEffect(() => {
    if (personalData && user) {
      if (user.fiscalCode === personalData.codFiscale) {
        setUserPersonalData(personalData);
      }
    }
  }, [personalData, user]);

  async function fetchHealthBook(fiscalCode, token) {
    try {
      const { data } = await createApi(token).post(`docs/libsan`, {
        fiscalCode,
      });
      return data.bytesArray;
    } catch (error) {
      return null;
    }
  }

  async function checkService(token) {
    try {
      const { status } = await createApi(token).get(`healthcheck`);
      if (status === 200) {
        setServiceState(true);
      } else {
        router.push("/error/2");
      }
    } catch (error) {
      setServiceState(false);
      router.push("/error/2");
    }
  }

  async function generateMMGReceipt(fiscalCode, doctorFiscalCode, token) {
    try {
      const { data } = await createApi(token).post(`mmg/receipt`, {
        fiscalCode,
        doctorFiscalCode,
      });
      return data.pdf;
    } catch (error) {
      router.push("/error");
      return null;
    }
  }

  async function findMMGs(fiscalCode, token) {
    try {
      setMMGs(null);
      const { data } = await createApi(token).post(`mmg/find`, { fiscalCode });
      if (data) {
        setMMGs(data);
      }
    } catch (error) {
      console.log({ ...error });
    }
  }

  async function fetchPersonalData(fiscalCode, token) {
    try {
      setPersonalData(null);
      const { data } = await createApi(token).post("personalData", {
        fiscalCode,
      });
      setPersonalData(data);
      if (data.datiSceltaMedico) {
        setCurrentMMG(data.datiSceltaMedico);
      }
    } catch (error) {
      console.log({ ...error });
      resetState();
      if (error.response?.status === 401 || error.response?.status === 404) {
        router.push("/error/1");
      } else {
        console.log(error);
        router.push("/error/2");
      }
    }
  }

  async function changeMMG(fiscalCode, doctorCode, token) {
    try {
      if (!SKIP_CHANGE_MMG_API) {
        let { data } = await createApi(token).post(`mmg/change`, { fiscalCode, doctorCode });
        const { flagEsito, codRichiesta, codAnomalia, descAnomalia } = data;
        const descQuery = descAnomalia ? "?desc=" + descAnomalia : "";
        if (flagEsito === 1) {
          setSuccessCode(codRichiesta);
          router.push("/success");
        } else if (codAnomaliaErrors.includes(codAnomalia)) {
          router.push("/error/3" + descQuery);
        } else {
          router.push("/error");
        }
      }

      //fetchPersonalData(fiscalCode);
    } catch (error) {
      const { response } = error;
      let pathToGo = "/error/";
      if (response.status == 401) {
        if (response.data.code === "30-days-limit") {
          pathToGo += "13";

        }
        router.push(pathToGo);
      }
    }
  }

  async function revocaMMG(fiscalCode, token) {
    console.log("revocaMMG", { fiscalCode, token })
    try {
      if (window.confirm("Stai per revocare il tuo medico di base o pediatra di libera scelta. Sei sicuro di voler proseguire?")) {
        if (!SKIP_CHANGE_MMG_API) {
          let { data } = await createApi(token).post(`mmg/revoca`, { fiscalCode });
          const { flagEsito } = data;
          if (flagEsito === 1) {
            window.alert("Operazione completata con successo, la pagina sarà ricaricata.")
            fetchPersonalData(fiscalCode, token)
          } else {
            window.alert("L'operazione non è andata a buon fine.")
          }
        }
      }
    } catch (error) {
      window.alert("L'operazione non è andata a buon fine.")
    }
  }

  return <MMGContext.Provider value={state}>{children}</MMGContext.Provider>;
}

function useMMG() {
  const context = useContext(MMGContext);
  if (context === undefined) {
    throw new Error("useMMG must be used within a MMGProvider");
  }
  return context;
}

export { MMGProvider, useMMG };
