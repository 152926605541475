import React, { useState } from "react";
import { getFullName } from "../lib/utils";
import Txt from "./Txt";
import Card from "./design/Card";
import styled from "styled-components";
import { textBlue, backgroundGray, yetAnotherDarkBlue, blueGray } from "../styles/colors";

const DoctorTableRow = ({ doctor, index, onDoctorSelected, checked }) => {
  const [exploded, setExploded] = useState(false);

  const isDoctorAvailable = () => {
    //test:return index % 3 === 0;
    return doctor.massimalista === "NO";
  };

  const handleSelection = () => {
    if (isDoctorAvailable()) {
      onDoctorSelected?.(doctor);
    }
  };

  return (
    <Card width="100% !important" style={{ padding: 0, backgroundColor: index % 2 === 0 ? backgroundGray : "white" }}>
      <div
        className={`flex ${exploded ? "exploded-border" : ""}`}
        style={{ cursor: isDoctorAvailable() ? "pointer" : "not-allowed" }}
        onClick={handleSelection}
        title={
          isDoctorAvailable() ? "" : "Il medico non può essere scelto poiché non ha disponibilità per altri assistiti."
        }
      >
        <Column className="bold justify-start text-left" width="25%">
          <div className="form-check w-100p">
            <input name="gruppo1" type="radio" readOnly checked={checked} aria-label={getFullName(doctor.nome, doctor.cognome)} disabled={!isDoctorAvailable()} />
            <label
              style={{
                paddingLeft: "25%",
                fontSize: "0.9rem",
                textTransform: "capitalize",
                fontWeight: "bold",
                color: isDoctorAvailable() ? textBlue : blueGray,
              }}
              htmlFor="radio1"
            >
              {getFullName(doctor.nome, doctor.cognome)}
            </label>
          </div>
        </Column>
        <Column width="15%">{doctor.dataNascita}</Column>
        <Column width="20%">
          <Txt>{"DoctorCode" + doctor.codTipoMedico}</Txt>
        </Column>
        <Column width="20%">{doctor.descComuneStudio}</Column>
        <Column className="flex-1" style={{ position: "relative", marginRight: 0 }}>
          <div style={{ marginRight: "1rem" }}>{doctor.indirizzoStudio}</div>

          <button
            tabIndex="0"
            onClick={(e) => {
              e.stopPropagation();
              setExploded(!exploded);
            }}
            className="shadow-button"
            style={{ position: "absolute", right: "0.2rem" }}
            aria-label={exploded ? "Chiudi Dettaglio Medico" : "Apri Dettaglio Medico"}
          >
            <svg
              className="icon"
            >
              <use
                href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#${exploded ? "it-collapse" : "it-expand"
                  }`}
              ></use>
            </svg>
          </button>
        </Column>
      </div>
      {exploded && (
        <>
          <div>
            <div colSpan={"6"}>
              <div
                style={{
                  borderTop: "1px solid gray",
                  margin: "0 1rem",
                  padding: "1rem",
                  paddingLeft: "4%",
                  textAlign: "left",
                  color: yetAnotherDarkBlue,
                  fontSize: "0.9rem",
                }}
              >
                <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                  <Txt>Codice fiscale</Txt>
                </div>
                <div style={{ fontWeight: "normal", marginBottom: "1rem" }}>{doctor.codFiscale}</div>
                <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                  <Txt>Numero di telefono studio medico</Txt>
                </div>
                <div style={{ fontWeight: "normal", marginBottom: "1rem" }}>{doctor.numTelStudioMedico || "-"}</div>
                <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
                  <Txt>Email</Txt>
                </div>
                <div style={{ fontWeight: "normal", marginBottom: "0.5rem" }}>
                  {doctor.emailIstituzionale || doctor.email || doctor.emailIstituzMedico || doctor.indPostaElettronica || "-"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default DoctorTableRow;

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;
  width: ${(props) => props.width || "unset"};
  min-width: ${(props) => props.width || "unset"};
  max-width: ${(props) => props.width || "unset"};
  font-size: 0.9rem;
  color: ${textBlue};
  margin-right: 0.5%;
  text-align: center;
`;
