import React from "react";
import { darkBlue, disabledGray } from "../../styles/colors";
import "../../styles/bootstrap-italia.min.css";

const Button = ({ icon, label, onClick, disabled, size = "xs", style = {}, className = "", afterIcon, children }) => {
  const buttonStyle = {
    backgroundColor: `${disabled ? disabledGray : darkBlue}`,
    color: disabled ? "white" : "",
    justifyContent: "center",
    fontSize: "0.8rem",
    marginRight: "1rem",
    marginLeft: "0",
    marginBottom: "1rem",
    ...style,
  };
  return (
    <button
      disabled={disabled}
      style={buttonStyle}
      className={`btn btn-primary btn-${size} btn-icon ${className}`}
      onClick={onClick}
    >
      {icon && (
        <svg className="icon icon-white">
          <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#${icon}`}></use>
        </svg>
      )}
      <span>{label}</span>
      {afterIcon && (
        <svg className="icon icon-white">
          <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#${afterIcon}`}></use>
        </svg>
      )}
      {children}
    </button>
  );
};

export default Button;
