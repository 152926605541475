import React, { useEffect, useState } from "react";
import HeaderButton from "./design/HeaderButton";
import Modal from "./layout/Modal";
import HeaderTitle from "./design/HeaderTitle";
import Txt from "./Txt";
import HeaderDescription from "./design/HeaderDescription";
import Separator from "./design/Separator";
import RoundIconLabel from "./design/RoundIconLabel";
import InputField from "./design/InputField";
import SwitchField from "./design/SwitchField";
import Joi from "joi";
import { yetAnotherDarkBlue } from "../styles/colors";

const regexForCF =
  /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;

const AddTutelageModal = ({ onAddTutelage, user, onHide, setShowModal }) => {
  const [targetFiscalCode, setTargetFiscalCode] = useState("");
  const [targetIdTeam, setTargetIdTeam] = useState("");
  const [selfCertification, setSelfCertification] = useState(false);
  const {
    nome,
    cognome,
    comuneNascita,
    dataNascita,
    comuneResidenza,
    indResidenza,
  } = user;

  const [errorCF, setErrorCF] = useState(null);
  const [errorItemTarget, setErrorItemTarget] = useState(null);
  const handleAddTutelage = () => {
    let errFC = fiscalCodeSchema.validate({ fiscalCode: targetFiscalCode });
    let errID = targetIdTeamSchema.validate({ targetIdTeam });
    if (!(errFC.error || errID.error)) {
      setShowModal(false);
      onAddTutelage?.({ targetFiscalCode, targetIdTeam, selfCertification });
    } else {
      if (errFC.error) setErrorCF("Codice Fiscale errato");
      if (errID.error) setErrorItemTarget("Codice errato");
    }
  };

  const targetIdTeamSchema = Joi.object().keys({
    targetIdTeam: Joi.string().alphanum().length(20).required().messages(),
  });

  const fiscalCodeSchema = Joi.object().keys({
    fiscalCode: Joi.string().regex(regexForCF),
  });

  const handleChangeTargetIdTeam = (e) => {
    setTargetIdTeam("8038000" + e.target.value);
  };

  useEffect(() => {
    if(targetFiscalCode.length===0){
      setErrorCF(null)
    }
    if(targetIdTeam.length-7===0){
      setErrorItemTarget(null)
    }
  }, [targetFiscalCode,targetIdTeam])
  

  return (
    <Modal
      className="flex-center flex-column"
      style={{ width: "60vw", padding: "4rem 5rem", margin: "2rem" }}
      onHide={() => onHide?.()}
    >
      <RoundIconLabel
        image="it_circle_plus.svg"
        color="gray"
        size="4rem"
        className="mb-1r"
      ></RoundIconLabel>
      <HeaderTitle className="mb-1r">
        <Txt>Aggiungi Assistito</Txt>
      </HeaderTitle>
      <HeaderDescription className="text-center">
        <Txt>Il sottoscritto</Txt> {nome} {cognome}, <Txt>nato a</Txt>{" "}
        {comuneNascita} <Txt>il</Txt> {dataNascita}, <Txt>residente a</Txt>{" "}
        {comuneResidenza}, <Txt>in</Txt> {indResidenza},{" "}
        <Txt>
          in qualità di esercente diritto sul minore, di cui di seguito i dati
        </Txt>
        :
      </HeaderDescription>
      <Separator />
      <div className="row flex w-100p">
        <div
          className={
            "col-xl-4 col-sm-12 d-flex pr-3" +
            (errorCF || errorItemTarget
              ? " align-items-start"
              : " align-items-end")
          }
        >
          <InputField
            className="flex-1"
            placeholder="Inserisci Codice Fiscale"
            label="Codice Fiscale"
            onChange={(e) => setTargetFiscalCode(e.target.value)}
            error={errorCF}
          />
        </div>
        <div className="col-xl-8 col-sm-12 d-flex align-items-end">
          <InputField
            className="flex-1"
            inputPrefix={<p style={{ display: "inline-block", marginBottom: "0px", paddingTop: "10px", marginRight: "28px"}}>8038000</p>}
            placeholder="Inserisci le ultime 13 cifre"
            label="Tessera Sanitaria"
            ariaLabel="Tessera Sanitaria"
            onChange={handleChangeTargetIdTeam}
            error={errorItemTarget}
          />
        </div>
      </div>

      <SwitchField
        onChange={(e) => setSelfCertification(e.target.checked)}
        containerClassName="mt-2r w-100p"
        switchClassName="ml-auto"
        label="Autorizzo al trattamento dei dati del minore, ai sensi delle vigenti disposizioni in materia di privacy"
        inputProps={{
          'aria-label': "Autorizzazione al trattamento dei dati del minore"
        }}
      ></SwitchField>
      <Separator></Separator>
      <div style={{ textTransform: "uppercase", fontWeight: "bold" }}>
        <Txt>Richiede</Txt>
      </div>
      <div>
        <Txt>
          sotto la propria responsabilità e ai sensi del DPR 445/2000, l'accesso
          al servizio per conto dell'assistito
        </Txt>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <HeaderButton
          label="Annulla"
          className="mr2rem outline"
          onClick={() => onHide?.()}
        />

        <HeaderButton
          disabled={!targetIdTeam || !targetFiscalCode || !selfCertification}
          label="Aggiungi"
          className="mr2rem"
          onClick={handleAddTutelage}
        />
      </div>
    </Modal>
  );
};

export default AddTutelageModal;
