import styled from "styled-components";
import { lightGray } from "../../styles/colors";

const Separator = styled.div`
  width: 100%;
  border: 1px solid ${lightGray};
  margin: 2rem 0;
`;

export default Separator;
