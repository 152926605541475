/* REFERENCE: https://blog.usejournal.com/internationalization-with-react-hooks-af37bed9f195 */
import React, { createContext, useReducer, useContext, useEffect } from "react";

import ENG from "../i18n/en.json";
import ITA from "../i18n/it.json";

// To make it easier to read from JSON files
const languages = {
  ITA,
  ENG,
};

// "ITA" is the fallback language
const getTranslate = (langCode) => (key) => languages[langCode][key] || languages["ITA"][key] || key;

const formatDate = (langCode) => (date) => {
  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);
  const intCode = languages[langCode]["language_code"];
  const monthStr = new Intl.DateTimeFormat(intCode, { month: "long" }).format(new Date(`${year}/${month}/${day}`));

  return `${day} ${monthStr} ${year}`;
};

const I18nContext = createContext();

function I18nReducer(state, action) {
  /* We will have two things in our context state, 
langCode will be the current language of the page
and translate will be the method to translate keys
into meaningful texts. Default language will be Italian */
  if (action.type === "setLanguage") {
    return {
      ...state,
      langCode: action.payload,
      translate: getTranslate(action.payload),
      getFormattedDate: formatDate(action.payload),
    };
  }
  else {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function I18nProvider({ children }) {
  const [state, dispatch] = useReducer(I18nReducer, {
    languages,
    langCode: "ITA",
    translate: getTranslate("ITA"),
    getFormattedDate: formatDate("ITA"),
  });

  const languageHandler = (event) => {
    const { detail } = event;
    const { langCode } = detail;
    dispatch({ type: "setLanguage", payload: langCode });
  };

  useEffect(() => {
    document.addEventListener("language-change", languageHandler);
  }, []);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
}

function useI18n() {
  const context = useContext(I18nContext);
  if (context === undefined) {
    throw new Error("useI18nState must be used within a I18nProvider");
  }
  return context;
}

export { I18nProvider, useI18n };
