import React from "react";
import { niceBlue } from "../../styles/colors";

const RoundIconLabel = ({
  color,
  label,
  icon,
  style = {},
  size = "2rem",
  className = "",
  image,
  labelColor = niceBlue,
  alt = "",
  ...props
}) => {
  return (
    <div
      {...props}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
      className={className}
    >
      <div className="flex-center" style={{ background: color, borderRadius: "50%", width: size, height: size }}>

        {!!image && (
          <img
            style={{ width: "80%", height: "80%", padding: "19%" }}
            src={`${process.env.PUBLIC_URL}/images/${image}`}
            alt={alt}
          ></img>
        )}
      </div>
      <div style={{ textTransform: "capitalize", fontSize: "0.8rem", fontWeight: "600", color: labelColor }}>
        {label}
      </div>
    </div>
  );
};

export default RoundIconLabel;
