import React, { useState, useRef, useEffect } from "react";
import { darkBlue } from "../styles/colors";
import HeaderTitle from "../components/design/HeaderTitle";
import HeaderDescription from "../components/design/HeaderDescription";
import HeaderButton from "../components/design/HeaderButton";
import { useRouter } from "../hooks/useRouter";
import { useMMG } from "../context/MMGContext";
import { Spinner } from "reactstrap";
import { usePCRC } from "../context/PCRCContext";

import Txt from "../components/Txt";
import { useUser } from "../context/UserContext";

const Success = () => {
  const router = useRouter();

  const { scrollTop } = usePCRC();
  const { generateMMGReceipt, currentPatient, personalData, fetchPersonalData, currentMMG } = useMMG();

  const [base64Pdf, setBase64Pdf] = useState(null);
  const [loading, setLoading] = useState(false);

  const inputEl = useRef(null);

  const { session } = useUser();

  const downloadMMGChangeReceipt = async () => {
    setLoading(true);
    const base64 = await generateMMGReceipt(currentPatient.fiscalCode, currentMMG.codFiscale, session?.token);
    setLoading(false);

    if (base64) {
      setBase64Pdf(base64);
      inputEl.current.click();
    } else {
      console.log("Error while generating downloadMMGChangeReceipt");
      router.push("/error");
    }
  };

  useEffect(scrollTop, []);

  useEffect(() => {
    if (currentPatient && !personalData && session?.token) {
      fetchPersonalData(currentPatient.fiscalCode, session.token);
    }
  }, [currentPatient, personalData, session?.token]);

  return (
    <>
      <a href={`data:application/pdf;base64,${base64Pdf}`} target="_blank" download="ricevuta-scelta-mmg.pdf">
        <div ref={inputEl}></div>
      </a>
      <div className="flex-center p-3r flex-column">
        <img src={`${process.env.PUBLIC_URL}/images/check.svg`} alt=""></img>

        <HeaderTitle className="my-2r">
          <Txt>Operazione conclusa con successo!</Txt>
        </HeaderTitle>
        <HeaderDescription className="text-center">
          <Txt>
            La scelta del Medico di Medicina Generale/Pediatria di Libera Scelta è stata correttamente effettuata
          </Txt>
          .
          <br />
          <Txt>
            Puoi scaricare la ricevuta contenente l'esito della richiesta tramite il pulsante "Download PDF", inoltre puoi
            visualizzare le informazioni del nuovo medico nella Homepage
          </Txt>
          .
        </HeaderDescription>
        <div className="flex-center">
          <HeaderButton
            className="outline"
            style={{ backgroundColor: darkBlue, padding: "0.5rem 2rem" }}
            label="Torna alla homepage"
            onClick={() => router.push("/")}
          ></HeaderButton>
          <HeaderButton
            style={{ backgroundColor: darkBlue, padding: "0.5rem 2rem" }}
            label="Download PDF"
            onClick={downloadMMGChangeReceipt}
            disabled={!personalData || loading}
          >
            {loading && <Spinner style={{ width: "1rem", height: "1rem", position: "absolute" }}></Spinner>}
          </HeaderButton>
        </div>
      </div>
    </>
  );
};

export default Success;
