import React, { useEffect } from "react";
import { usePCRC } from "../../context/PCRCContext";
import { useRouter } from "../../hooks/useRouter";

const menu = [
  {
    type: "megamenu",
    title: "Scelta e Revoca",
    sections: [
      {
        items: [{ label: "FAQ", link: "/faq", iframed: true }],
      },
    ],
  },
];

const Layout = ({ children }) => {
  const { setHeaderImages, resetBodyPadding, setMenu, connected } = usePCRC();

  const router = useRouter();

  useEffect(() => {
    if (connected) {
      setHeaderImages({
        sx: `${window.location.href}${process.env.PUBLIC_URL}/header/header.svg`,
        center: false,
        dx: false,
        padding: "1rem",
        height: "150px",
      });
      resetBodyPadding();

      setMenu(menu);
    }
  }, [connected]);

  useEffect(() => {
    const handleChangePage = (event) => {
      const { detail } = event;
      const { page } = detail;

      router.push(page);
    };

    document.addEventListener("change-page", handleChangePage);
    return () => document.removeEventListener("change-page", handleChangePage);
  }, []);

  return <>{children}</>;
};

export default Layout;
