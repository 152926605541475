import React from "react";
import { darkBlue, blueGray } from "../../styles/colors";
import styled from "styled-components";


const IconContainer = styled.div`
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`


const Stepper = ({ style = {}, step, label, className = "", active = false, done = false }) => {
  return (
    <div
      className={`flex-center ${className}`}
      style={{
        padding: "0.5rem",
        paddingBottom: 0,
        ...style,
      }}
    >
      <IconContainer>
      {done ? (
        <svg className="icon" style={{ fill: blueGray }}>
          <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-check-circle`}></use>
        </svg>
      ) : (
        <div
          className="flex-center"
          style={{
            background: active ? darkBlue : "white",
            borderRadius: "50%",
            width: "1.5rem",
            height: "1.5rem",
            color: active ? "white" : blueGray,
            border: `1px solid ${active ? "white" : blueGray}`,
          }}
        >
          {step}
        </div>
      )}
      </IconContainer>
      <div
        style={{
          textTransform: "uppercase",
          marginLeft: "1rem",
          fontWeight: "bold",
          color: active ? darkBlue : blueGray,
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default Stepper;
