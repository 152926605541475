import React from "react";
import styled from "styled-components";

const Modal = ({ children, style = {}, onHide, className = "" }) => {
  return (
    <Container>
      <div className={`${className} modal-container`}
        style={{
          ...style,
        }}
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Chiudi Modale"
          onClick={() => onHide?.()}
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {children}
      </div>
    </Container>
  );
};

export default Modal;

const Container = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .modal-container {
    background: white;
    min-height: 20vh;
    min-width: 20vw;
    border-radius: 5px;
    position: relative;
    padding: 2rem;
  }
  @media only screen and (max-width: 600px) {
    .modal-container {
      min-width: 90vw;
    }
    
  }
`;
