const guideLabels = {
    'home-scelta/01.png': `Azioni Disponibili. Scelta Medico - Cliccando qui potrai procedere con la scelta del Medico di Medicina Generale/Pediatra di Libera Scelta per te o per un altro assistito.
    Altri Assistiti - Cliccando qui potrai selezionare un altro assistito su cui operare.`,
    'home-scelta/02.png': `Documenti dell'assistito. In questa sezione potrai scaricare il Libretto Sanitario dell'assistito per il quale si sta operando.`,
    'home-cambio/01.png': `Dati del Medico. In questa sezione troverai un riepilogo dei dati anagrafici e  dei contatti del Medico di Medicina Generale/Pediatra di Libera Scelta assegnato.`,
    'home-cambio/02.png': `Azioni Disponibili. Cambio medico - Cliccando qui potrai procedere con il cambio del Medico di Medicina Generale/Pediatra di Libera Scelta per te o per un altro assistito.
    Altri assistiti - Cliccando qui potrai selezionare un altro assistito su cui operare.`,
    'home-cambio/03.png': `Documenti dell'assistito. In questa sezione potrai scaricare il libretto sanitario dell'assistito per il quale si sta operando.`,
    'gestione-altri-assistiti/01.png': 'Azioni Disponibili. Aggiungi Assistito - Cliccando qui potrai aggiungere un altro assistito per il quale operare. Procedi - Cliccando qui potrai procedere con la gestione dell\' assistito selezionato.',
    'gestione-altri-assistiti/02.png': `Tabella con colonne: Nome e Cognome, Codice Fiscale, Tessera Sanitaria, Azioni. Prima riga tabella: Francesco Valente, VLFNF500000000, 00000000001, Icona Elimina.
    Assistiti Inseriti. Ogni riga della seguente tabella corrisponde a un assistito per cui puoi operare. Inoltre potrai usufruire delle seguenti funzionalità: Elimina - Cliccando su questa icona potrai eliminare un assistito da te selezionato.`,
    'medici-1-cambio/01.png': `Azione disponibile. Avanti - Dopo aver selezionato un Medico di Medicina Generale/Pediatra di Libera Scelta, cliccando qui potrai procedere con la scelta.`,
    'medici-1-cambio/02.png': `Filtri di ricerca. In questa sezione potrai ricercare un Medico di Medicina Generale/Pediatra di Libera Scelta disponibile applicando dei filtri. Puoi cercare per nome e cognome, selezionare un Comune e/o tipologia di medico.
    Cliccando sul pulsante "Applica" visualizzerai la lista dei medici disponibili correlati alla tua ricerca.`,
    'medici-1-cambio/03.png': `Tabella con colonne: Nome e cognome, Data di Nascita, Tipo di Medico, Distretto Sanitario, Comune dello studio, Indirizzo. Prima riga: Mario Rossi, 01/02/1980, Medico di Medicina Generale, 00, Napoli, Via Verdi, 1 - Napoli NA.
    Medici Disponibili. Ogni riga della seguente tabella corrisponde a un medico di Medicina Generale/Pediatra di Libera Scelta disponibile, che potrai selezionare e scegliere per te o per l'assistito per il quale stai operando.
    Nota: Cliccando sulla freccia presente in ogni riga della tabella, è possibile visualizzare informazioni aggiuntive e i contatti relativi al medico corrispondente.`,
    'medici-2-cambio/01.png': `Azioni Disponibili. Indietro - Cliccando qui potrai tornare alla sezione precedente. Conferma - Cliccando qui confermerai il cambio o la scelta del Medico di Medicina Generale/Pediatra di Libera Scelta.`,
    'medici-2-cambio/02.png': `Riepilogo dati del medico. In questa sezione troverai un riepilogo dei dati anagrafici e dei contatti del Medico di Medicina Generale/Pediatra di Libera Scelta selezionato.`,
    'medici-riepilogo-scelta/01.png': `Azioni Disponibili. Indietro - Cliccando qui potrai tornare alla sezione precedente. Conferma - Cliccando qui confermerai il cambio o la scelta del Medico di Medicina Generale/Pediatra di Libera Scelta.`,
    'medici-riepilogo-scelta/02.png': `Riepilogo dati del medico. In questa sezione troverai un riepilogo dei dati anagrafici e dei contatti del Medico di Medicina Generale/Pediatra di Libera Scelta selezionato.`,
    'medici-1-scelta/01.png': `Azione Disponibile. Avanti - Cliccando qui, dopo aver selezionato un Medico di Medicina Generale/Pediatra di Libera Scelta, potrai procedere con la scelta.`,
    'medici-1-scelta/02.png': `Filtri di ricerca. In questa sezione potrai ricercare un Medico di Medicina Generale/Pediatra di Libera Scelta disponibile applicando dei filtri. Puoi cercare per nome e cognome e/o selezionare un Comune.
    Cliccando sul pulsante "Applica" visualizzerai la lista dei medici disponibili correlati alla tua ricerca.`,
    'medici-1-scelta/03.png': `Tabella con colonne: Nome e cognome, Data di Nascita, Tipo di Medico, Distretto Sanitario, Comune dello studio, Indirizzo. Prima riga: Mario Rossi, 01/02/1980, Medico di Medicina Generale, 00, Napoli, Via Verdi, 1 - Napoli NA.
    Medici Disponibili. Ogni riga della seguente tabella corrisponde a un medico di Medicina Generale/Pediatra di Libera Scelta disponibile, che potrai selezionare e scegliere per te o per l'assistito per il quale stai operando.
    Nota: Cliccando sulla freccia presente in ogni riga della tabella, è possibile visualizzare informazioni aggiuntive e i contatti relativi al medico corrispondente.`
}

export default guideLabels;