import React from "react";
import Button from "./Button";
import { darkBlue } from "../../styles/colors";
import styled from "styled-components";

const HeaderButton = styled(Button)`
  padding: 0.5rem 3rem;
  min-width: 12rem;
  margin: 0 1rem;
  border: 2px solid transparent !important;

  &.outline {
    background-color: transparent !important;
    border: 2px solid ${darkBlue} !important;
    color: ${darkBlue};
    &:hover {
      color: ${darkBlue};
    }
  }

  &.link {
    background-color: transparent !important;
    border: none !important;
    color: ${darkBlue} !important;
    min-width: unset;
    box-shadow: none !important;
    padding: 0.5rem 0;
    &:hover {
      text-decoration: underline !important;
    }
    &:active,
    &.active,
    &.active::focus,
    &:focus {
      box-shadow: none;
      color: ${darkBlue} !important;
    }
  }
  @media only screen and (max-width: 400px) {
    max-width: 70%;
    min-width: 0px;
  }
`;

/*
const HeaderButton = (props) => {
  return <Button {...props} style={{ backgroundColor: darkBlue, padding: "0.5rem 3rem" }}></Button>;
};
*/
export default HeaderButton;
