import styled from "styled-components";

const Card = styled.div`
  //box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #fff;
  border-right: none;
  padding: 1rem;
  margin: 1rem 0;
  width: ${({ width}) => width ?? ""};
  max-width: ${({ width }) => width ?? ""};
  &.sm {
    width: 15rem;
  }
  @media only screen and (max-width: 400px) {
    max-width: 90%;
    flex-wrap: wrap;
  }
`;

export default Card;
