import React, { useEffect, useState } from "react";
import PageHeader from "../components/layout/PageHeader";
import HeaderTitle from "../components/design/HeaderTitle";
import HeaderDescription from "../components/design/HeaderDescription";
import Txt from "../components/Txt";
import Breadcrumb from "../components/Breadcrumb";
import Header2 from "../components/design/Header2";
import Counter from "../components/design/Counter";
import { PageSubTitle } from "../components/PageElements";
import PageCounter from "../components/PageCounter";
import DropdownBox from "../components/DropdownBox";
import { useApi } from "../context/ApiContext";
import { FAQ_URL } from "../constants/constants";

const MAX_PER_PAGE = 10;

const FAQ = () => {
  const [FAQs, setFAQs] = useState(null);
  const { createApi } = useApi();

  // Stato pagine
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(undefined);

  async function findFAQs() {
    try {
      const { data } = await createApi().get(FAQ_URL);
      setFAQs(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    async function loadFAQ() {
      if (!FAQs) {
        findFAQs();
      }
      if (Array.isArray(FAQs) && FAQs.length > 0) {
        setPage(1);
        if (FAQs.length % MAX_PER_PAGE === 0) {
          setMaxPage(FAQs.length / MAX_PER_PAGE);
        }
        else {
          setMaxPage(Math.floor(FAQs.length / MAX_PER_PAGE) + 1);
        }
      }
      else {
        setPage(0);
        setMaxPage(0);
      }
    }
    loadFAQ();
  }, [FAQs]);

  return (
    <>
      <PageHeader style={{ position: "relative" }}>
        <div className="flex align-stretch">
          <div className="flex-column justify-start">
            <Breadcrumb currentLabel="FAQ - Domande e Risposte" style={{ position: "absolute", top: 0 }} />
            <HeaderTitle>
              <Txt>FAQ - Domande e Risposte</Txt>
            </HeaderTitle>
            <HeaderDescription>
              <Txt>
                In questa sezione puoi consultare le risposte relative ad alcune domande frequenti relative al servizio
                in uso
              </Txt>
              .
            </HeaderDescription>
          </div>
        </div>
      </PageHeader>
      <div style={{ padding: "3rem" }}>
        <Header2 className="flex justify-start align-center">
          <Txt>Domande Frequenti per Scelta e Recova MMG/PLS</Txt>
          {!!FAQs && <Counter>{FAQs.length}</Counter>}
        </Header2>
        {
          FAQs == null || !Array.isArray(FAQs) ?
            <PageSubTitle className="text-center mt-3"><span className="bold-text">Al momento non è presente alcuna domanda riguardante questo servizio.</span></PageSubTitle>
            :
            FAQs?.slice((page - 1) * MAX_PER_PAGE, ((page - 1) * MAX_PER_PAGE) + MAX_PER_PAGE)?.map(({ domanda, risposta }, index) =>
              <DropdownBox key={(index + 1) * page} className="mt-4" header={<button className="shadow-button flex-grow-1" style={{ color: "#17324D" }}><span className="bold-text">{domanda}</span></button>} isHeaderClickable>
                <div className="mt-4 mb-2 pr-5">
                  {risposta.split("\\n").map((v, i) => <span className="answer-text" key={i}>{v}<br /></span>)}
                </div>
              </DropdownBox>
            )
        }
        {
          Array.isArray(FAQs) && FAQs.length > 0 && <PageCounter page={page} setPage={setPage} maxPage={maxPage} />
        }
      </div>
    </>
  );
};

export default FAQ;
