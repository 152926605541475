import React, { useState, useEffect } from "react";
import Txt from "./Txt";
import { useUser } from "../context/UserContext";
import { getFullName } from "../lib/utils";
import { Table, TableHeader, TableHeaderRow, Td, Tr } from "./design/TableElements";
import RemoveTutelage from "./RemoveTutelage";

const TutelagesTable = ({ tutelages = [], onTutelageClick, currentTutelage }) => {
  const { user, userPersonalData } = useUser();

  const [selectedTutelage, setSelectedTutelage] = useState(currentTutelage);

  useEffect(() => {
    if (selectedTutelage) {
      onTutelageClick?.(selectedTutelage);
    }
  }, [selectedTutelage]);

  const renderRow = (index, fiscalCode, name, surname, idTEAM = "") => {
    return (
      <Tr
        key={index}
        even={index % 2 === 0}
        onClick={() => setSelectedTutelage({ fiscalCode, fullName: getFullName(name, surname) })}
      >
        <Td className="bold text-left">
          <div className="form-check">
            <input aria-label={getFullName(name, surname)} name="gruppo1" type="radio" readOnly checked={selectedTutelage?.fiscalCode === fiscalCode} />
            <label style={{ paddingLeft: "25%" }} htmlFor="radio1">
              {getFullName(name, surname)}
            </label>
          </div>
        </Td>
        <Td>{fiscalCode}</Td>
        <Td>{idTEAM}</Td>
        <Td>
          <RemoveTutelage fiscalCode={fiscalCode} index={index} fullName={getFullName(name, surname)}></RemoveTutelage>
        </Td>
      </Tr>
    );
  };

  return (
    <>
    <div style={{width: "100%", overflowX: "scroll"}}>
      <div style={{minWidth: "1000px"}}>
      <Table>
        <thead>
          <TableHeaderRow>
            <TableHeader>
              <Txt>Nome e cognome</Txt>
            </TableHeader>
            <TableHeader>
              <Txt>Codice Fiscale</Txt>
            </TableHeader>
            <TableHeader>
              <Txt>Tessera Sanitaria</Txt>
            </TableHeader>
            <TableHeader className="border-none">
              <Txt>Azioni</Txt>
            </TableHeader>
          </TableHeaderRow>
        </thead>
        <tbody>
          {renderRow(0, user.fiscalCode, user.name, user.familyName, userPersonalData?.idTEAM)}
          {!!tutelages.length &&
            tutelages.map(({ targetPersonalData, targetFiscalCode }, index) => {
              return renderRow(
                index + 1,
                targetFiscalCode,
                targetPersonalData?.nome,
                targetPersonalData?.cognome,
                targetPersonalData?.idTEAM
              );
            })}
        </tbody>
      </Table>
      </div>
      </div>
    </>
  );
};

export default TutelagesTable;
