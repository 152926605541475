import React, { useState } from "react";
import { useTutelage } from "../context/TutelageContext";
import RemoveTutelageModal from "./RemoveTutelageModal";
import RoundIconLabel from "./design/RoundIconLabel";
import { textBlue } from "../styles/colors";
import { useUser } from "../context/UserContext";

const RemoveTutelage = ({ index, fiscalCode, fullName }) => {
  const [showModal, setShowModal] = useState(false);
  const { removeTutelage } = useTutelage();
  const { session } = useUser();

  const handleRemoveTutelage = () => {
    removeTutelage(fiscalCode, session?.token);
    setShowModal(false);
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    if (index) {
      setShowModal(true);
    }
  };

  return (
    <React.Fragment>
    <button
      className="shadow-button"
      disabled={index === 0}
      onClick={handleRemoveClick}
      aria-label={"Elimina assistito: " + fullName}
    >
      <RoundIconLabel
        image="trash.png"
        label="Elimina"
        color={index ? "red" : "lightGray"}
        labelColor={index ? textBlue : "lightGray"}
        style={{ cursor: index ? "pointer" : "unset" }}
      ></RoundIconLabel>
    </button>
    {showModal && (
      <RemoveTutelageModal onConfirm={handleRemoveTutelage} onHide={() => setShowModal(false)}></RemoveTutelageModal>
    )}
    </React.Fragment>
  );
};

export default RemoveTutelage;
