import React, { useState, useEffect } from "react";
import Txt from "./Txt";
import DoctorTableRow from "./DoctorTableRow";
import Paginator from "./design/Paginator";
import styled from "styled-components";
import { backgroundGray, yetAnotherDarkBlue } from "../styles/colors";

const itemsForPage = 10;

const DoctorsTable = ({ doctors = [], onDoctorSelected, selectedDoctor }) => {

  const [index, setIndex] = useState(0);

  const handleChangePage = (page) => {
    const newIndex = (page - 1) * itemsForPage;
    setIndex(newIndex);
  };

  useEffect(() => {
    setIndex(0);
  }, [doctors]);

  return (
    <div style={{width: "100%", overflowX: "auto"}}>
      <div style={{minWidth: "1000px"}}>
      <div className="flex w-100p mt-1r">
        <Column width="25%">
          <Txt>Nome e cognome</Txt>
        </Column>
        <Column width="15%">
          <Txt>Data di Nascita</Txt>
        </Column>
        <Column width="20%">
          <Txt>Tipo di Medico</Txt>
        </Column>
        {/*<Column>
              <Txt>Distretto Sanitario</Txt>
            </Column>*/}
        <Column width="20%">
          <Txt>Comune dello Studio</Txt>
        </Column>
        <Column className="flex-1" style={{ marginRight: 0 }}>
          <Txt>Indirizzo</Txt>
        </Column>
      </div>
      {doctors.slice(index, index + itemsForPage).map((doctor, index) => {
        return (
          <DoctorTableRow
            key={`doctor.codFiscale${index}`}
            index={index}
            doctor={doctor}
            onDoctorSelected={() => onDoctorSelected?.(doctor)}
            checked={selectedDoctor?.codFiscale === doctor.codFiscale}
          //checked={true}
          ></DoctorTableRow>
        );
      })}
      {!!(doctors.length > itemsForPage) && (
        <Paginator count={doctors.length} itemsForPage={itemsForPage} onChangePage={handleChangePage} ariaName="Tabella Medici."></Paginator>
      )}
      </div>
    </div>
  );
};

export default DoctorsTable;

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;
  width: ${(props) => props.width || "unset"};
  min-width: ${(props) => props.width || "unset"};
  max-width: ${(props) => props.width || "unset"};

  font-size: 0.9rem;
  font-weight: bold;
  background: ${backgroundGray};
  color: ${yetAnotherDarkBlue};
  margin-right: 0.5%;
`;
