import React from "react";
import Card from "./design/Card";
import Header2 from "./design/Header2";
import Header3 from "./design/Header3";
import styled from "styled-components";
import Txt from "./Txt";
import { textBlue } from "../styles/colors";
import WarningBox from "./WarningBox";

const MMGInfoCard = ({ data, style = {}, isExpiring }) => {
  return (
    <div style={style}>
      <Header2>
        <Txt>Dati del Medico</Txt>
      </Header2>
      {
        isExpiring &&
        <WarningBox title="Importante" className="mt-2r mb-2r">
          <Txt>Si avvisa che il medico selezionato scadrà tra meno di 30 giorni. Si invita dunque a selezionare un altro medico attraverso il portale o rivolgendosi al Distretto Sanitario di competenza prima della scadenza.</Txt>
        </WarningBox>
      }
      {
        !!data && <Card>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <Cell>
              <Header3>
                <Txt>Nome e cognome</Txt>
              </Header3>
              <span style={{ textTransform: "capitalize", wordBreak: "break-word" }}>
                {data?.nome?.toLowerCase?.()} {data?.cognome?.toLowerCase?.()}
              </span>
            </Cell>
            <Cell>
              <Header3>
                <Txt>Codice Fiscale</Txt>
              
              </Header3>
              <span style={{ wordBreak: "break-all" }}>
              {data?.codFiscale}
              </span>
            </Cell>
            <Cell>
              <Header3>
                <Txt>Data di Nascita</Txt>
              </Header3>
              <span style={{ wordBreak: "break-all" }}>
              {data?.dataNascita && data.dataNascita !== "Invalid Date" ? data.dataNascita : "-"}
              </span>
            </Cell>
            <Cell>
              <Header3>
                <Txt>Tipo di Medico</Txt>
              </Header3>
              {data?.codTipoMedico ? <Txt>{`DoctorCode${data.codTipoMedico}`}</Txt> : "-"}
            </Cell>
            <Cell>
              <Header3>
                <Txt>Distretto Sanitario</Txt>
              </Header3>
              {data?.numDistUSL ?? data?.distrettoConv ?? "-"}
            </Cell>
            <Cell>
              <Header3>
                <Txt>Numero di telefono studio medico</Txt>
              </Header3>
              <span style={{ wordBreak: "break-all" }}>
              {data.numTelStudioMedico || "-"}
              </span>
            </Cell>
            <Cell>
              <Header3>
                <Txt>Email</Txt>
              </Header3>
              <span style={{ wordBreak: "break-all" }}>
              {data.emailIstituzionale || data.email || data.emailIstituzMedico || data.indPostaElettronica || "-"}
              </span>
            </Cell>
            <Cell>
              <Header3>
                <Txt>Comune dello Studio</Txt>
              </Header3>
              <span style={{ textTransform: "capitalize" }}>{data?.descComuneStudio?.toLowerCase?.() || "-"}</span>
            </Cell>
            <Cell>
              <Header3>
                <Txt>Indirizzo dello Studio</Txt>
              </Header3>
              <span style={{ textTransform: "capitalize" }}>{data?.indirizzoStudio?.toLowerCase?.() || "-"}</span>
            </Cell>
            <Cell></Cell>
            <Cell></Cell>
          </div>
        </Card>
      }
    </div>
  );
};

export default MMGInfoCard;

const Cell = styled.div`
  flex-basis: 20%;
  margin: 1rem;
  color: ${textBlue};
  @media screen and (max-width: 1024px) {
    flex-basis: 40%;
  }
`;
