import { textBlue, backgroundGray } from "../../styles/colors";
import styled from "styled-components";
import { Header2 } from "../../styles/text-styles";

const Table = styled.table`
  ${Header2}
  font-size: 15px;
  margin-top: 2rem;
  color: ${textBlue};
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
`;

const TableHeader = styled.th`
  font-weight: 700;
  font-style: normal;
  border-right: 2px solid white;
  min-width: 10rem;
  padding: 5px 0;

  padding-left: 20px;
  text-align: center;
  background: ${backgroundGray};
`;

const TableHeaderRow = styled.tr`
  border-top: 2px solid white;
`;

const Td = styled.td`
  font-weight: normal;
  padding: 1rem;
  padding-left: 20px;
  &.bold {
    font-weight: bold;
  }
  &.text-left {
    text-align: left;
  }
`;

const Tr = styled.tr`
  background: ${(props) => (props.even ? backgroundGray : "transparent")};
  height: 4rem;
  text-align: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  text-transform: capitalize;

  &.real {
    cursor: pointer;
  }
`;
const Tbody = styled.tbody`
  background: ${(props) => (props.even ? backgroundGray : "transparent")};
  height: 4rem;
  text-align: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid black;
  text-transform: capitalize;
  //border-top: 1rem solid transparent;

  & tr.exploded-border {
    box-shadow: 0 20px 0 ${(props) => (props.even ? backgroundGray : "transparent")};
  }
`;

export { Table, Td, Tr, Tbody, TableHeader, TableHeaderRow };
