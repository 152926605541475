import React, { useState, useEffect } from "react";
import styled from "styled-components";

const BoxedSelect = styled.a`
  border-color: #BACCD9;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  margin-left: 4px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MaxPageContainer = styled.a`
  height: 40px;
  width: 40px;
  margin-left: 16px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ArrowIcon = ({ direction, isEnabled, onClick }) => {

  const degrees = direction === "left" ? "-90" : direction === "right" && "90";
  return (
    <button className="shadow-button" style={{ opacity: isEnabled ? "1" : "0.5" }} onClick={onClick}>
      <svg stroke={isEnabled ? "#007A4D" : "#5E7887"}
        style={{ cursor: "pointer", width: "24px", height: "24px", transform: `rotate(${degrees}deg)` }}>
        <use href={`${process.env.PUBLIC_URL}/bootstrap-italia/svg/sprite.svg#it-collapse`}></use>
      </svg>
    </button>
  )
}

const PageCounter = ({ page, maxPage, setPage }) => {

  const [leftArrowEnabled, setLeftArrowEnabled] = useState(false);
  const [rightArrowEnabled, setRightArrowEnabled] = useState(false);

  useEffect(() => {
    setLeftArrowEnabled(true);
    setRightArrowEnabled(true);
    // Disabilita le frecce se si trova ad un estremo di pagina
    if (page === 1 || page === 0) setLeftArrowEnabled(false);
    if (page === maxPage) setRightArrowEnabled(false);
  }, [page])

  useEffect(() => {
    setRightArrowEnabled(true);
    if (page === maxPage) setRightArrowEnabled(false);
  }, [maxPage])

  return (
    <div className="d-flex justify-content-center align-items-center mt-5">
      <ArrowIcon isEnabled={leftArrowEnabled} direction="left" onClick={() => {
        leftArrowEnabled && setPage(page - 1);
      }} />
      <BoxedSelect>{page}</BoxedSelect> / <MaxPageContainer>{maxPage}</MaxPageContainer>
      <ArrowIcon isEnabled={rightArrowEnabled} direction="right" onClick={() => {
        rightArrowEnabled && setPage(page + 1);
      }} />
    </div>
  )
}

export default PageCounter;
