import styled from "styled-components";
import { darkBlue, lightGray } from "../../styles/colors";
import React from "react";
import Select from "react-select";

const defaultAriaLiveMessages = {
  guidance: (props) => {
    const {
      isSearchable,
      isMulti,
      isDisabled,
      tabSelectsValue,
      context,
    } = props;
    switch (context) {
      case 'menu':
        return `Premere Freccia Sù o Freccia Giù per selezionare l'opzione${
          isDisabled
            ? ''
            : ', premere Invio per confermare l\'opzione corrente'
        }, premere ESC per uscire dal menù${
          tabSelectsValue
            ? ', premere Tab per confermare l\'opzione ed uscire dal menù'
            : ''
        }.`;
      case 'input':
        return `${props['aria-label'] || 'Select'} è selezionata ${
          isSearchable ? ',type to refine list' : ''
        }, premere Freccia Giù per aprire menù, ${
          isMulti ? ' premere Freccia Sinistra per evidenziare le opzioni confermate' : ''
        }`;
      case 'value':
        return 'Premere Freccia Sinistra e Freccia Destra per navigare tra le opzioni confermate, premere Backspace per eliminare l\'opzione selezionata.';
      default:
        return '';
    }
  },
  onFilter: (props) => {
    const { inputValue } = props;
    return `${
      inputValue ? ' for search term ' + inputValue : ''
    }.`;
  },
  onChange: (props) => {
    const { action, label = '', isDisabled } = props;
    switch (action) {
      case 'deselect-option':
      case 'pop-value':
      case 'remove-value':
        return `Opzione ${label}, deselezionata.`;
      case 'select-option':
        return isDisabled
          ? `Opzione ${label} è disabilitata. Selezionare un'altra opzione.`
          : `Opzione ${label}, selezionata.`;
      default:
        return '';
    }
  },

  onFocus: (props) => {
    const {
      context,
      focused = {},
      options,
      label = '',
      selectValue,
      isDisabled,
      isSelected,
    } = props;

    const getArrayIndex = (arr, item) =>
      arr && arr.length ? `${arr.indexOf(item) + 1} di ${arr.length}` : '';

    if (context === 'value' && selectValue) {
      return `Opzione ${label} focalizzata, ${getArrayIndex(selectValue, focused)}.`;
    }

    if (context === 'menu') {
      const disabled = isDisabled ? ' disabilitato' : '';
      const status = `${isSelected ? 'confermato' : 'selezionato'}${disabled}`;
      return `Opzione ${label} ${status}, ${getArrayIndex(options, focused)}.`;
    }
    return '';
  }
};

const BaseSelect = styled(Select)`
  color: ${darkBlue};
  width: ${(props) => props.width || "10rem"};
  min-width: ${(props) => props.width || "10rem"};
  max-height: 2rem;
`;

const indicatorSeparatorStyle = {
  display: "none",
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const customStyles = {
  clearIndicator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided, state) => {
    return { ...provided };
  },
  valueContainer: (provided, state) => {
    return { ...provided, padding: "0 8px", height: "2.5rem", width: "100%" };
  },
  container: (provided, state) => {
    return { ...provided, width: "100%" };
  },
  control: (provided, state) => {
    return { ...provided, height: "100%", borderColor: lightGray, width: "100%" };
  },
};

const StyledSelect = ({ label, className, style = {}, ...props }) => (
  <div className={className} style={style}>
    <div style={{ fontWeight: "bold", color: darkBlue, fontSize: "0.9rem" }}>{label}</div>
    <BaseSelect
      {...props}
      isSearchable={false}
      indicatorSeparator={false}
      components={{ IndicatorSeparator }}
      ariaLiveMessages={defaultAriaLiveMessages}
      aria-label={label}
      styles={{ ...customStyles, ...(props.styles || {}) }}
    ></BaseSelect>
  </div>
);

export default StyledSelect;
