import React, { useState, useEffect } from "react";
import PageHeader from "../components/layout/PageHeader";
import HeaderTitle from "../components/design/HeaderTitle";
import HeaderDescription from "../components/design/HeaderDescription";
import HeaderButton from "../components/design/HeaderButton";
import MMGInfoCard from "../components/MMGInfoCard";
import HealthBookDownloadCard from "../components/HealthBookDownloadCard";
import WarningBox from "../components/WarningBox";
import Txt from "../components/Txt";
import HelpDocButton from "../components/HelpDocButton";
import TutoringAlert from "../components/TutoringAlert";
import { useUser } from "../context/UserContext";
import { useMMG } from "../context/MMGContext";
import { useRouter } from "../hooks/useRouter";
import AlreadyChangedMsg from "../components/AlreadyChangedMsg";
import { IS_DEV } from "../constants/constants";
import Loader from "../components/design/Loader";
import { HeaderContainer } from "../components/design/HeaderContainer";
import styled from 'styled-components';
import moment from "moment";

const DocInfoContainer = styled.div`
    padding: 2rem; 
    margin: 1rem 0;
    @media only screen and (max-width: 500px) {
      padding: 0.5rem;
      margin: 1rem 0;
    }
`;

function checkExpiration(expiringDate) {
  return expiringDate.hour(0).minute(0).second(0).diff(moment().hour(0).minute(0).second(0), 'days') <= 30
}

const Home = () => {
  const { user, isLogged, session } = useUser();
  const { currentPatient, fetchPersonalData, personalData, currentMMG, checkService, revocaMMG } = useMMG();
  const [firstChoiceMode, setFirstChoiceMode] = useState(null);

  const [isExpiring, setIsExpiring] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (isLogged && session?.token) {
      checkService(session.token);
    }
  }, [isLogged, session?.token]);

  useEffect(() => {
    if (currentPatient && session?.token) {
      fetchPersonalData(currentPatient.fiscalCode, session.token);
      //findMMGs(currentPatient.fiscalCode);
    }
  }, [currentPatient, session?.token]);

  useEffect(() => {
    setFirstChoiceMode(!!currentMMG);
    if (currentMMG && currentMMG.dataFineScelta) {
      const dateString = currentMMG.dataFineScelta.split("/").reverse().join("/");
      setIsExpiring(checkExpiration(moment(dateString)));
    }
  }, [currentMMG]);

  return !!personalData ? (
    <>
      <PageHeader>
        <TutoringAlert />
        <div className="flex align-stretch col-xs">
          <img
            src={`${process.env.PUBLIC_URL}/images/gruppo-home.svg`}
            alt=""
          ></img>
          <HeaderContainer
            className="flex-column justify-start"
          >
            <HeaderTitle>
              <Txt>Benvenuto</Txt> {user.name} {user.familyName},
            </HeaderTitle>
            <HeaderDescription>
              <Txt>
                In questa sezione potrai scegliere o modificare il tuo Medico di
                Medicina Generale/Pediatria di Libera Scelta e scaricare il
                libretto sanitario. Potrai operare per te o per i tuoi figli
                minorenni
              </Txt>
              .
            </HeaderDescription>
            <div style={{ marginTop: "auto" }}>
              <HeaderButton
                label={firstChoiceMode ? "Scelta Medico" : "Cambio Medico"}
                disabled={
                  (currentMMG?.cambiatoUltimi30giorni && !IS_DEV) ||
                  !(!!personalData && firstChoiceMode !== null)
                }
                className="mr-2r"
                style={{ marginLeft: 0 }}
                onClick={() => router.push("/doctor")}
              />
              {/*<HeaderButton
                label={"Revoca Medico"}
                disabled={currentMMG?.dataFineScelta != null}
                className="mr-2r"
                style={{ marginLeft: 0 }}
                onClick={() => revocaMMG(currentPatient.fiscalCode, session.token)}
              />*/}
              <HeaderButton
                label="Altri Assistiti"
                className="outline"
                onClick={() => router.push("/tutelages")}
              />
            </div>
            {currentMMG?.cambiatoUltimi30giorni && (
              <AlreadyChangedMsg className="mt-1r"></AlreadyChangedMsg>
            )}
          </HeaderContainer>
        </div>
        <HelpDocButton
          images={
            firstChoiceMode
              ? ["home-scelta/01.png", "home-scelta/02.png"]
              : [
                "home-cambio/01.png",
                "home-cambio/02.png",
                "home-cambio/03.png",
              ]
          }
        ></HelpDocButton>
      </PageHeader>
      <DocInfoContainer>
        <HealthBookDownloadCard />
        {!!currentMMG ? (
          currentMMG.attivo ? (
            <MMGInfoCard data={currentMMG} isExpiring={isExpiring} />
          ) : (
            <WarningBox title="Importante" className="mt-2r">
              <Txt>Il tuo medico assegnato non è più disponibile o lo hai revocato.</Txt>
            </WarningBox>
          )
        ) : (
          <WarningBox title="Importante" className="mt-2r">
            <Txt>Attualmente non hai alcun medico assegnato.</Txt>
          </WarningBox>
        )}
        <WarningBox title="Importante" className="mt-2r">
          <div style={{ wordBreak: "break-word" }}>
            <Txt >
              In alcuni casi l'assegnazione del Medico di Medicina Generale /
              Pediatra di libera scelta non può essere effettuata dal portale e
              quindi è necessario rivolgersi al Distretto Sanitario di competenza.
              A scopo esemplificativo alcune casistitiche sono: assistenza
              temporanea, gestione in deroga, assegnazione di un Medico di
              Medicina Generale/Pediatra di Libera Scelta che afferisce ad un
              ambito territoriale di assistenza diverso da quello dell'assistito.
            </Txt>
          </div>
        </WarningBox>
      </DocInfoContainer>
    </>
  ) : (
    <Loader></Loader>
  );
};

export default Home;
