import { prussianBlue } from "./colors";

/* Text styles */
const TextStyle = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 42px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -2px;
  color: #17324d;
`;

const TextStyle3 = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

const TextStyle2 = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #455b71;
`;

const TextStyle4 = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 29px;
  font-weight: 600;
  font-style: bold;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0;
`;

const Lora = `
font-family: Lora;
font-size: 16px;
font-weight: bold;
font-style: italic;
font-stretch: normal;
line-height: 1.75;
letter-spacing: normal;
color: #455b71;
`;

const Abstract = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #455b71;
`;

const LucidaTitle = `
  font-family: LucidaGrande;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

const SearchBodyText = `
  font-family:  'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${prussianBlue};
`;

const CDATitle = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 42px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

const TitoloModale = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: #455b71;
`;

const Header2 = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #17324d;
`;

const Header24px = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #17324d;
`;

const UnderlinedHeader = `
font-family: 'Titillium Web', sans-serif;
font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

const TitleH3Bold32 = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #000000;
`;

const TitleH4Bold18 = ` 
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #000000;
`;

const BodyText18px = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #000000;
`;

const TitoloDocumento = `
  font-family: 'Titillium Web', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  color: #17324d;
`;

const WidgetText = `
font-family: 'Titillium Web', sans-serif;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: rgba(0, 0, 0, 0.5);
`;

export {
  TitoloModale,
  TextStyle,
  TextStyle2,
  TextStyle3,
  TextStyle4,
  Lora,
  Abstract,
  LucidaTitle,
  SearchBodyText,
  CDATitle,
  Header2,
  Header24px,
  TitleH3Bold32,
  UnderlinedHeader,
  TitoloDocumento,
  TitleH4Bold18,
  BodyText18px,
  WidgetText,
};
