import React, { useState, useEffect } from "react";
import PageHeader from "../components/layout/PageHeader";
import HeaderTitle from "../components/design/HeaderTitle";
import HeaderDescription from "../components/design/HeaderDescription";
import HeaderButton from "../components/design/HeaderButton";
import Header2 from "../components/design/Header2";

import Txt from "../components/Txt";

import { useMMG } from "../context/MMGContext";
import Breadcrumb from "../components/Breadcrumb";
import Stepper from "../components/design/Stepper";
import DoctorsTable from "../components/DoctorsTable";
import TutoringAlert from "../components/TutoringAlert";
import DoctorsFilterBar from "../components/DoctorsFilterBar";
import MMGInfoCard from "../components/MMGInfoCard";
import HelpDocButton from "../components/HelpDocButton";
import { Spinner } from "reactstrap";
import { useUser } from "../context/UserContext";
import { darkBlue, blueGray } from "../styles/colors";


const DoctorManager = () => {
  const { mmgs, findMMGs, currentPatient, currentMMG, changeMMG } = useMMG();
  const [filteredList, setFilteredList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const { session } = useUser();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const changeStep = () => {
    if (step === 1) {
      setStep(2);
    } else {
      setStep(1);
    }
  };

  useEffect(() => {
    if (currentPatient && !mmgs) {
      findMMGs(currentPatient.fiscalCode, session?.token);
    }
  }, [currentPatient]);

  useEffect(() => {
    if (mmgs) {
      setFilteredList(mmgs);
    }
  }, [mmgs]);

  const handleConfirm = () => {
    setLoading(true);
    changeMMG(currentPatient.fiscalCode, selectedDoctor.codRegionale, session?.token);
  };

  return (
    <div style={{ position: "relative" }}>
      <PageHeader style={{ position: "relative" }}>
        <TutoringAlert />

        <div className="flex align-stretch">
          <div className="flex-column justify-start">
            <Breadcrumb
              currentLabel={!!currentMMG ? "Cambio Medico" : "Scelta Medico"}
              style={{ position: "absolute", top: 0 }}
            />
            <HeaderTitle>
              <Txt>{!!currentMMG ? "Cambio Medico" : "Scelta Medico"}</Txt>
            </HeaderTitle>
            <HeaderDescription style={{ maxWidth: "60%" }}>
              <Txt>
                In questa sezione puoi modificare l'attuale Medico di Medicina Generale/Pediatra di Libera Scelta per
                l'assistito selezionato. Confermando, la scelta del nuovo Medico e la revoca dell'attuale avranno
                effetto immediato. Non potrai effettuare una nuova scelta per i prossimi 30 giorni, se non recandoti
                presso il Distretto Sanitario di competenza
              </Txt>
              .
            </HeaderDescription>
            <div className="d-flex flex-row-reverse" style={{ padding: "0px", margin: "0px"}}>
              {step === 1 && (
                <div className="mr-3r">
                  <HeaderButton disabled={!selectedDoctor} label="Avanti" onClick={changeStep} />
                </div>
              )}
              {step === 2 && (
                <div className="flex-center mr-3r flex-wrap">
                  <HeaderButton label="Indietro" className="mr2rem outline" onClick={() => setStep(1)} />
                  <HeaderButton label="Conferma" className="mr2rem" onClick={handleConfirm} disabled={loading}>
                    {loading && <Spinner style={{ width: "1rem", height: "1rem", position: "absolute" }}></Spinner>}
                  </HeaderButton>
                </div>
              )}
            </div>
          </div>
        </div>
        {step === 1 && (
          <HelpDocButton
            images={
              currentMMG
                ? ["medici-1-cambio/01.png", "medici-1-cambio/02.png", "medici-1-cambio/03.png"]
                : ["medici-1-scelta/01.png", "medici-1-scelta/02.png", "medici-1-scelta/03.png"]
            }
          ></HelpDocButton>
        )}
        {step === 2 && (
          <HelpDocButton
            images={
              currentMMG
                ? ["medici-2-cambio/01.png", "medici-2-cambio/02.png"]
                : ["medici-2-scelta/01.png", "medici-2-scelta/02.png"]
            }
          />
        )}
      </PageHeader>
      <div style={{ padding: "2rem", margin: "1rem 0" }}>
        <div className="flex-center">
          <Stepper
            step="1"
            label="scelta nuovo medico"
            className="flex-1 mr-1r"
            active={step === 1}
            done={step > 1}
          ></Stepper>
          <Stepper step="2" label="riepilogo" className="flex-1" active={step === 2}></Stepper>
        </div>
        <div className="d-flex mb-2r">
          <div className="flex-1 mr-1r flex-center" style={{
            borderBottom: `1px solid ${step === 1 ? darkBlue : blueGray}`,
            padding: "0.5rem",}}
          />
          <div className="flex-1 flex-center" style={{
            borderBottom: `1px solid ${step === 2 ? darkBlue : blueGray}`,
            padding: "0.5rem",}}
          />
        </div>
        {step === 1 && (
          <>
            <Header2>
              <Txt>Elenco dei Medici</Txt>
            </Header2>
            <HeaderDescription>
              <Txt>
                Seleziona il Medico di Medicina Generale/Pediatra di Libera Scelta che vuoi assegnare all'assistito
              </Txt>
              .<br />
              <Txt>Puoi applicare dei filtri per affinare la tua ricerca</Txt>.
            </HeaderDescription>
            <DoctorsFilterBar mmgs={mmgs} onListChange={setFilteredList}></DoctorsFilterBar>
            <DoctorsTable
              doctors={filteredList}
              onDoctorSelected={setSelectedDoctor}
              selectedDoctor={selectedDoctor}
            ></DoctorsTable>
          </>
        )}
        {step === 2 && <MMGInfoCard data={selectedDoctor} />}
      </div>
    </div>
  );
};

export default DoctorManager;
